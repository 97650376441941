import { Injectable } from '@angular/core';

/**
 * userProfile
 * アプリケーション全体で利用するユーザー情報
*/
export let userProfile: any = [];
userProfile['loginToken'] = '';
userProfile['memberId'] = '';
userProfile['userName'] = '';
userProfile['planId'] = '';
userProfile['loggedIn'] = false;

/**
 * アプリケーション全体で利用するユーザー情報の管理クラス
 * 
 * 
 * 
 */
@Injectable()
export class UserProfileService {

    /**
     * ユーザープロファイルのリセット
     * @return void
     */
    resetUserProfile(): void {
        //        sessionStorage.clear();
        localStorage.clear();
        userProfile['id'] = '';
        userProfile['loginToken'] = '';
        userProfile['memberId'] = '';
        userProfile['planId'] = '';
        userProfile['userName'] = '';
        userProfile['loggedIn'] = false;
        userProfile['loggedInAsPartner'] = false;
        userProfile['selectedDate'] = null;
        userProfile['roleId'] = '';
        userProfile['partner_staff'] = false;    //  スタッフかパートナーどうか（loggedInAsPartnerがtrueのとき有効でtrueのときスタッフ）
    }

    /**
     * ユーザープロファイルのセット
     * @param profileValue セットするユーザー情報オブジェクト
     * @return void
     */
    setUserProfile(profileValue: any): void {
        // セットされた情報を展開して値をセット
        Object.keys(profileValue).forEach(function (value) {
            // userProfile[value] = profileValue[value];
            //            sessionStorage.setItem(value, profileValue[value]);
            //  DataServiceをインジェクトしたかったが、なぜかうまくいかないので、直接localStrageを操作する
            localStorage.setItem(value, profileValue[value]);
        });
    }

    /**
     * ユーザープロファイルの取得
     * @param profileKey string　取得するユーザープロファイルのキー
     * @return ユーザープロファイル情報 any (string OR boolean)
     */
    getUserProfile(profileKey: string): any {
        // return userProfile[profileKey];
        //        return sessionStorage.getItem(profileKey);
        return localStorage.getItem(profileKey);

    }

    isLogin(): boolean {
        return this.getUserProfile('loggedIn');
    }

    get userName(): string {
        return this.getUserProfile('userName');
    }
    get roleId(): number {
        return this.getUserProfile('roleId');
    }
    //
    //  
    get id(): number {
        return this.getUserProfile('id');
    }

    get partner_staff(): string {
        return this.getUserProfile('partner_staff');
    }

    setSelectedDate(date: Date) {
        this.setUserProfile({ selectedDate: date.toISOString() });
    }
    getSelectedDate(): Date {
        let date = this.getUserProfile('selectedDate');
        if (date != null) {
            return new Date(date);
        } else {
            return new Date();
        }
    }

    isSuperAdmin(): boolean {
        return this.roleId == 2 ? true : false;
    }

}

