import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SchoolModel } from 'app/models/school.model';
import { PartnerModel } from 'app/models/partner.model';
import { TransportationExpenseModel } from 'app/models/transportation-expense.model';
import { TransferExpense, TransferExpenseStack } from './transfer-expense-stack';

@Component({
  selector: 'app-concierge-setting',
  templateUrl: './concierge-setting.component.html',
  styleUrls: ['./concierge-setting.component.scss']
})
export class ConciergeSettingComponent implements OnInit {
  public schools: SchoolModel[];   //  スクール一覧
  public partners: PartnerModel[]; //  コンシェルジュ一覧
  private mode: string;
  private transferExpenseStack: TransferExpenseStack;

  private lastTimeoutId: NodeJS.Timeout;

  constructor(
    private router: Router,
    private shiftService: ShiftService,
  ) {
    this.shiftService.showMenuLink = true;
    this.shiftService.title = 'コンシェルジュ設定';

    this.mode = 'staff';

    this.transferExpenseStack = new TransferExpenseStack();

  }

  ngOnInit() {
    this.getSchools();
  }
  /**
   * スクール一覧を取得
   */
  getSchools(): void {

    this.shiftService.getSchools(this.mode, null).subscribe(schools => {

      if (schools.length > 0) {
        this.schools = schools;

        //  パートナー一覧を取得
        this.getAllPartnerSchool();

      }

    });
  }

  /**
     * パートナー一覧を取得
     */
  getAllPartnerSchool(): void {

    this.shiftService.getAllPartnerSchool(this.mode).subscribe(partners => {

      if (partners.length > 0) {
        this.partners = partners;

      }

    });
  }

  inSchoolArray(schoolId: number, schools: SchoolModel[]): boolean {

    for (let i = 0; i < schools.length; i++) {
      if (schools[i].school_id == schoolId) {
        return true;
      }
    }
    return false;
  }

  /**
   * スクールに対応する交通費を取得
   * @param schoolId スクールID
   * @param expenses 交通費情報一覧
   */
  getExpenseBySchool(schoolId: number, expenses: TransportationExpenseModel[]): string {
    if (expenses) {
      for (let i = 0; i < expenses.length; i++) {
        if (expenses[i].school_id == schoolId) {
          return expenses[i].expense.toString();
        }
      }

    }
    return '';

  }

  onUpdateSchool(value: any, schoolId: number, partnerId: number): void {
    console.log('onUpdateSchool:' + value);

    this.shiftService.updatePartnerSchool(schoolId, partnerId, value ? 'insert' : 'delete').subscribe(partners => {


      if (partners.length > 0) {
        let partner = partners[0];
        for (let i = 0; i < this.partners.length; i++) {
          if (this.partners[i].partner_id == partnerId) {
            this.partners[i] = partner;
          }
        }

      }

    });

  }

  onUpdateKey(value: any, schoolId: number, partnerId: number): void {
    console.log('onUpdateKey:' + value);

    this.shiftService.updateKeyPosession(schoolId, partnerId, value ? 'insert' : 'delete').subscribe(partners => {

      if (partners.length > 0) {
        let partner = partners[0];
        for (let i = 0; i < this.partners.length; i++) {
          if (this.partners[i].partner_id == partnerId) {
            this.partners[i] = partner;
          }
        }

      }

    });

  }

  /**
   * パートナー名を取得
   * @param model パートナー
   */
  partnerName(model: any) {
    return PartnerModel.partnerName(model);
  }


  /**
   * 交通費更新
   * @param value 交通費
   */
  updateTransportationExpense(value: string, schoolId: number, partnerId: number) {

    this.transferExpenseStack.push(value, schoolId, partnerId);

    if (this.lastTimeoutId) {
      clearTimeout(this.lastTimeoutId);
      this.lastTimeoutId = null;
    }

    //  １秒入力がなかったら交通費をサーバーに通知する
    this.lastTimeoutId = setTimeout(() => {

      this.lastTimeoutId = null;

      while (1) {
        let expense: TransferExpense = this.transferExpenseStack.pop();

        if (expense) {
          this.shiftService.updateTransportationExpense(expense.schoolId, expense.partnerId, expense.value).subscribe(partners => {

            if (partners.length > 0) {
              let partner = partners[0];
              for (let i = 0; i < this.partners.length; i++) {
                if (this.partners[i].partner_id == partnerId) {
                  this.partners[i] = partner;
                }
              }

            }

          });

        } else {
          break;
        }

      }

    }, 3000);
  }


  isHolidayWeek(week: number, partner: PartnerModel) {

    return partner.holiday_weeks.indexOf(week) >= 0 ? true : false;
  }

  /**
   * 定休曜日のチェック状態が変更されたとき
   * 
   * @param value 
   * @param partner 
   * @param week 
   */
  onUpdateHolidayWeek(value: any, partner: PartnerModel, week: number) {

    let partnerId = partner.partner_id;
    this.shiftService.updateStaffRegularHolidayWeek(partnerId, week, value).subscribe(result => {

      if (result === true) {
        PartnerModel.updateStaffRegularHolidayWeek(partner, week, value);

        for (let i = 0; i < this.partners.length; i++) {
          if (this.partners[i].partner_id == partnerId) {
            this.partners[i] = partner;
            break;
          }
        }

      }

    });

  }
}
