import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../modules/auth/index';
import { UserProfileService } from '../../value/index';
import { ModalService } from '../../components/modal/index';

@Component({
  moduleId: module.id,
  selector: 'app-component-side-bar',
  providers: [AuthService, ModalService],
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  private loginFlag: boolean;
  private displayProperty: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private userProfile: UserProfileService,
    private modal: ModalService
  ) {
  }

  ngOnInit() {

  }

}
