import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService, DataService, ProgressService } from '../../modules/util';
import { HttpService } from '../../modules/http/index';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UserProfileService } from '../../value/index';
import { apiEndpoint } from '../../value/index';
import { AuthService } from '../../modules/auth/index';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  providers: [UtilService, DataService, HttpService],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public userName: string;
  public password: string;
  public loginError = false;

  constructor(
    private util: UtilService,
    private data: DataService,
    private http: HttpService,
    private sanitizer: DomSanitizer,
    public progressService: ProgressService,
    private router: Router,
    private auth: AuthService,
    private userProfile: UserProfileService
  ) {

  }

  login() {
    this.auth.logout();
    this.userProfile.resetUserProfile();

    let response: any;

    // フォームからの入力値をセット
    const jsonBody = JSON.stringify(
      {
        'userName': this.userName,
        'password': this.password,
      },
    );

    this.progressService.start();

    // ユーザー情報登録処理
    this.http.post(apiEndpoint.loginApi, jsonBody).subscribe(
      data => {
        this.progressService.done();

        response = data;
        if (response.member_id !== '') {
          const setProfileData = {
            id: response.id,
            memberId: response.memberId,
            userName: response.userName,
            planId: response.planId,
            loginToken: response.loginToken,
            roleId: response.roleId,
            loggedIn: true,
          };
          // ユーザープロファイルをセット
          this.userProfile.setUserProfile(setProfileData);
          // 企業情報が登録済であればindexに遷移
          this.router.navigate(['/home']);
        } else {
          this.loginError = true;
        }
      },
      err => {
        this.progressService.done();

        console.dir(err);
      }
    );
  }

}
