import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-staff-holiday',
  templateUrl: './staff-holiday.component.html',
  styleUrls: ['./staff-holiday.component.scss']
})
export class StaffHolidayComponent implements OnInit {
  public partners: PartnerModel[]; //  コンシェルジュ休暇
  public selectedMonthDays: number;

  constructor(
    private router: Router,
    public shiftService: ShiftService,
    public datePipe: DatePipe,


  ) {
    this.shiftService.title = 'コンシェルジュ休暇設定' + this.datePipe.transform(this.shiftService.selectedDate, "yyyy/M");;
    this.shiftService.showMenuLink = true;

  }

  ngOnInit() {

    this.selectedMonthDays = this.shiftService.getSelectedMonthDays();
    this.getAllStaffHolidays();

  }




  /**
   * パートナー一覧を取得
   */
  getAllStaffHolidays(): void {

    this.shiftService.getAllStaffHolidays().subscribe(partners => {

      if (partners.length > 0) {
        this.partners = partners;

      }

    });
  }



  /**
     * パートナー名を取得
     * @param model パートナー
     */
  partnerName(model: any) {
    return PartnerModel.partnerName(model);
  }



  /**
   * 日付のクラスを取得　（赤か青）
   * @param partner 
   * @param day 
   */
  dateClass(partner: PartnerModel, day: number) {

    let date = this.shiftService.formatSelectedDateString(day);
    if (PartnerModel.isBlueHoliday(partner, date)) {
      return 'bg-blue';
    }
    if (PartnerModel.isRedHoliday(partner, date)) {
      return 'bg-red';
    }
    return '';

  }

  onClickDate(partnerIndex: number, day: number) {
    let partner = this.partners[partnerIndex];
    let date = this.shiftService.formatSelectedDateString(day);
    let isHoliday = !(PartnerModel.isBlueHoliday(partner, date) || PartnerModel.isRedHoliday(partner, date));
    let holiday_type = 1;   // 赤休日を設定
    this.shiftService.updateStaffHoliday(partner.partner_id, date, isHoliday ? 1 : 0, holiday_type).subscribe(result => {

      if (result === true) {
        PartnerModel.updateHoliday(partner, date, isHoliday);
        this.partners[partnerIndex] = partner;

      }

    });
  }
  getWeekText(day: number) {
    return this.shiftService.getJapaneseWeekText(day);
  }

  onResetBlue() {

    let holiday_type = 2;   // 青休日を設定
    this.shiftService.updateStaffHoliday(0, this.shiftService.selectedDate.toDateString(), 2, holiday_type).subscribe(result => {
      if (result === true) {
        //  再設定
        this.getAllStaffHolidays();

      }

    });

  }


}