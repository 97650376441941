import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router } from '@angular/router';
import { ShiftClassModel } from 'app/models/shift-class.model';


import { Subscription } from 'rxjs';

import { SchoolModel } from 'app/models/school.model';
import { ChankInfoModel } from 'app/models/chank-info.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { UtilService } from 'app/modules/util';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  providers: [PdfService, UtilService]
})
export class BlockComponent implements OnInit {
  public schools: SchoolModel[];       // スクール一覧
  public currentSchoolId: number;      // 選択中のスクールID
  public days: Date[];                 // 日付一覧
  public currentDay: Date;             // 選択中の日付
  public tentativeShifts: TentativeShiftModel[];



  constructor(
    private router: Router,
    public shiftService: ShiftService,
    private pdfService: PdfService,
    private util: UtilService,
  ) {
    this.shiftService.title = 'ブロック機能';
    this.shiftService.showMenuLink = true;
  }

  ngOnInit() {
    this.getSchools();
  }
  /**
   * スクール一覧を取得
   */
  getSchools(): void {

    this.shiftService.getSchools('partner', null).subscribe(schools => {

      if (schools.length > 0) {
        this.schools = schools;

        this.currentSchoolId = schools[0].school_id;
        //  日付情報を取得
        this.getSchoolDays(this.currentSchoolId);

      }

    });
  }

  /**
   * スクールの開講日一覧を取得
   */
  getSchoolDays(schoolId: number): void {

    this.shiftService.getSchoolDays(schoolId).subscribe(days => {

      if (days.length > 0) {
        this.days = days;

        if (days.indexOf(this.currentDay) == -1) {
          if (this.currentDay) {
            this.openModal("休校設定されているため日付を1日に変更します。");

          }
          this.currentDay = days[0];

        }


        //  ブロック付きの仮シフト情報を取得
        this.getTentativeShiftWithBlockInfos(this.currentSchoolId, this.currentDay);

      }

    });
  }


  /**
   * シフト情報を取得
   * @param schoolId　スクールID
   * @param day 日付
   */
  getTentativeShiftWithBlockInfos(schoolId: number, day: Date): void {

    this.shiftService.getTentativeShiftWithBlockInfos(schoolId, day).subscribe(rows => {

      if (rows) {
        this.tentativeShifts = rows;
      }

    });
  }
  /**
   * スクール変更時
   * 日付を取得
   * 
   * @param value 
   */
  onChangeSchool(value: number) {

    console.log(value);
    this.currentSchoolId = value;

    this.getSchoolDays(value);
  }

  /**
   * 日付変更時
   * @param value 日付
   */
  onChangeDay(value: Date) {

    this.currentDay = value;
    //  ブロック付きの仮シフト情報を取得
    this.getTentativeShiftWithBlockInfos(this.currentSchoolId, this.currentDay);
  }

  /**
   * セルクリック時
   * ブロックとブロック解除のトグル
   * @param event 
   * @param lesson 
   */
  onClick(event: any, tentativeShiftIndex: number, shiftClassIndex: number) {

    let tentativeShift = this.tentativeShifts[tentativeShiftIndex];
    let shiftClassInfo = tentativeShift.shiftClassInfos[shiftClassIndex];
    if (shiftClassInfo.isFilledUp) {
      // 仮シフト設定されているときだけ処理

      shiftClassInfo.isBlocked = this.util.toggleValue(shiftClassInfo.isBlocked, 2);
      //      shiftClassInfo.isBlocked = shiftClassInfo.isBlocked ? false : true;

      //  DB更新
      this.postTentativeShiftInfos('update_block', tentativeShift.partnerId, tentativeShift.boothNumber, [shiftClassInfo.timeNumber], shiftClassInfo.isBlocked);


    }

  }
  /**
   * 仮シフト　ブロック情報更新
   */
  postTentativeShiftInfos(method: string, partnerId: number, boothNumber: number, timeNumbers: number[], isBlocked: number): void {


    let tentativeShiftModel = new TentativeShiftModel();
    tentativeShiftModel.method = method;
    tentativeShiftModel.schoolId = this.currentSchoolId;
    tentativeShiftModel.date = this.currentDay;
    tentativeShiftModel.partnerId = partnerId;
    tentativeShiftModel.boothNumber = boothNumber;
    tentativeShiftModel.timeNumbers = timeNumbers;
    tentativeShiftModel.isBlocked = isBlocked;


    this.shiftService.postTentativeShiftInfos(tentativeShiftModel).subscribe(result => {


    });
  }

  captureScreen() {
    this.pdfService.export('ブロック機能.pdf', document.getElementById('main-content'));
  }


  /**
   * モーダル表示
   * @param message メッセージ
   */
  openModal(message: string) {
    if (message) {

      console.debug(message);
      this.shiftService.errorMessage = message;

    }
  }

}
