import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { LoginCheck } from './modules/auth';
import { ProgressService } from './modules/util/progress.service';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  constructor(
    public loginCheck: LoginCheck,
    private progress: ProgressService
  ) {

  }
}
