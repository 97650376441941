import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { ShiftService } from '../../shift.service';
import { Observable } from 'rxjs'
import { PartnerModel } from 'app/models/partner.model';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-lp-select-modal',
  //   template: `
  //   <div class="modal-content">
  //     <div class="modal-header">
  //       <h4>{{title || 'Confirm'}}</h4>
  //     </div>
  //     <div class="modal-body">
  //       <p>{{message || 'Are you sure?'}}</p>
  //     </div>
  //     <div class="modal-footer">
  //       <button type="button" class="btn btn-primary" (click)="confirm()">はい</button>
  //       <button type="button" class="btn btn-outline-danger" (click)="cancel()">いいえ</button>
  //       </div>
  //   </div>
  // `
  templateUrl: './lp-select-modal.component.html',
  styleUrls: ['./lp-select-modal.component.scss']
})
export class LpSelectModalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;
  message: string;

  filteredPartners: PartnerModel[] = [];
  usersForm: FormGroup;
  isLoading = false;

  constructor(
    private shiftService: ShiftService,
    private fb: FormBuilder
  ) {
    super();
  }

  /**
   * 初期処理
   *
   * @memberof LpSelectModalComponent
   */
  ngOnInit() {
    this.usersForm = this.fb.group({
      userInput: null
    })


    this.usersForm
      .get('userInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.shiftService.getPartnersByName(value)
          .pipe(
            finalize(() => this.isLoading = false),
          )
        )
      )
      .subscribe(models => this.filteredPartners = models);
  }

  displayFn(partner: PartnerModel) {
    if (partner) { return partner.partner_fname_e; }
  }

  onClick(e) {
    this.result = true;
    this.close();
  }
  confirm() {
    // on click on confirm button we set dialog result as true,
    // ten we can get dialog result from caller code
    this.result = true;
    this.close();
  }
  cancel() {
    this.result = false;
    this.close();
  }


}
