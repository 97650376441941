import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { CommonComponentsModule } from '../../components/common-components.module';

@NgModule({
  imports: [CommonModule, CommonComponentsModule],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class LoginModule { }
