import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserProfileService } from '../../value/index';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import 'rxjs/add/operator/catch'; // don't forget this, or you'll get a runtime error

/**
 * HTTP(S)通信サービスを提供するクラス
 */
@Injectable()
export class HttpService {

  private loginToken: string;

  /**
   * @param Http
   * @constructor
   */
  constructor(
    private http: HttpClient,
    private userProfile: UserProfileService,
  ) {
    this.loginToken = this.userProfile.getUserProfile('loginToken');
  }

  /**
   * Getでhttp通信を行う
   * @param URL
   * @return getで取得したJSONデータ
   */
  get<T>(url: string, token?: string): Observable<T> {
    const options = this.setHeader();
    return this.http.get<T>(url, { headers: options })
    // .catch((error: HttpErrorResponse) => {
    //   const errMsg = (error.message) ? error.message :
    //     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    //   return Observable.throw(errMsg);

    // });
  }

  /**
   * Getでhttp通信を行いPromiseをリターン
   * @param URL
   * @return Promise getで取得したJSONデータ
   */
  /*
  promiseGet(url: string, token?: string) {
    const options = this.setHeader(token);

    return this.http.get(url, options)
      .map((res: Response) => res.json())
      .catch(this.handleError).toPromise();

  }
  */

  /**
   * Postでhttp通信を行う
   * @param URL
   * @param data
   * @return postで取得したJSONデータ
   */
  post<T>(url: string, data: any, auth = true): Observable<T> {
    let options: any;
    options = this.setHeader();

    return this.http.post<T>(url, data, { headers: options })

  }

  /**
   * Putでhttp通信を行う
   * @param URL
   * @param data
   * @return postで取得したJSONデータ
   */
  put<T>(url: string, data: any): Observable<T> {
    const options = this.setHeader();

    return this.http.put<T>(url, data, { headers: options })

  }

  /**
   * DELETEでhttp通信を行う
   * @param URL
   * @return getで取得したJSONデータ
   */
  delete<T>(url: string): Observable<T> {
    const options = this.setHeader();

    return this.http.delete<T>(url, { headers: options })

  }

  /**
   * XMLHttpRequestでmultipart/form-dataのフォームデータを送信
   * 主にファイルの同期アップロード処理で使用
   * @param URL
   * @param formData
   * @return post結果
   */
  async formPost(url: string, formData: any) {

    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onload = function () {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        return JSON.parse(xmlHttp.responseText);
      }
    };
    xmlHttp.open('POST', url, true); // true:非同期、false:同期
    xmlHttp.send(formData);
  }

  /**
   * http通信を行う際の共通ヘッダーをセット
   * @return httpリクエストヘッダー
   */
  private setHeader(): HttpHeaders {
    let loginToken = this.userProfile.getUserProfile('loginToken');

    const headerJson = {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${loginToken}`,
    }
    const headers = new HttpHeaders(headerJson);

    return headers;
  }

  /**
   * HTTP通信エラーハンドラー
   * @return エラーメッセージ
   */
  private handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }


}
