import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { DataService } from '../util/index';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenCheck implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private data: DataService,
  ) { }

  async canActivate() {
    const sessionToken = this.data.getSessionStorage('token');

    // トークンチェックの格納用
    let value: boolean;

    // トークンのチェック
    await this.auth.checkToken(sessionToken).then(
      data => {
        value = true;
      },
      err => {
        value = false;
      }
    );

    // tokenの認証が切れていたらログイン画面に遷移
    if (await value === false) {
      this.router.navigate(['/login']);
    }

    return await value;
  }

}
