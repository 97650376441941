import { Component, OnInit } from '@angular/core';
import { UtilService, DataService } from '../../modules/util';
import { HttpService } from '../../modules/http/index';
import { AuthService } from '../../modules/auth/index';
import { ModalService } from '../../components/modal/index';

declare var $: any;

@Component({
  moduleId: module.id,
  selector: 'app-home',
  providers: [UtilService, DataService, HttpService, AuthService, ModalService],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(

  ) {
  }

  ngOnInit() {
    // bodyが切れてしまう問題対応
    $('body').layout('fix');
  }


}
