/**
 * クラス単位での情報
 */
export class ShiftClassModel {
    isFilledUp: boolean;    //  仮シフト決定
    isClosed: boolean;    //  クローズされているかどうか
    isBlocked: number;     //  0:ブロックされていない、1:BLOCK、2:TTP
    timeNumber: number;    //  タイム時間番号 1-16
    text: string;           //  クラス時間表示　9:00 とか


    static setFilledUpByNumbers(models: ShiftClassModel[], timeNumbers: number[], isFilledUp: boolean = true) {

        for (let i = 0; i < timeNumbers.length; i++) {
            let timeNumber = timeNumbers[i];

            ShiftClassModel.setFilledUpByNumber(models, timeNumber, isFilledUp);

        }
    }

    static setFilledUpByNumber(models: ShiftClassModel[], timeNumber: number, isFilledUp: boolean) {
        for (let i = 0; i < models.length; i++) {
            if (models[i].timeNumber == timeNumber) {
                models[i].isFilledUp = isFilledUp;
                break;
            }
        }

    }
}

