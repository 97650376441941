import { Injectable } from '@angular/core';

/**
 * ユーティリティ関連処理を提供するクラス
 */
@Injectable()
export class UtilService {

  // ボタンがクリックされたら
  fileClickEvent(id: string) {
    var fileButton = document.getElementById(id);
    var clickEvent = document.createEvent('MouseEvents');
    clickEvent.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    fileButton.dispatchEvent(clickEvent);
  }

  // blobUrltoFile
  blobUrltoFile(blobUrl: string, fileName: string, callback: any) {

    // リターンするファイルオブジェクトの初期値
    var fileObject: any = 'FILE';

    // blobの取得が完了したら
    var promise = new Promise((resolve, reject) => {
      // XMLHttpRequestの生成
      var xhr = new XMLHttpRequest();

      // GETでblobのURLからblob取得
      xhr.open('GET', blobUrl, true);
      xhr.responseType = 'blob';

      xhr.onload = function (e) {
        if (xhr.status === 200) {
          // blob
          var myBlob = xhr.response;
          // blobをファイルオブジェクトに変換
          fileObject = new File([myBlob], fileName, { type: myBlob.type });
          resolve();
        }
      };

      // 取得開始
      xhr.send();
    });

    promise.then(
      () => {
        return callback(fileObject);
      },
    );
  }

  // リストのページング処理用にIDの配列を生成してリターン
  makeListsPaging(pagingIds: any, divide: number) {
    const pagingIdArray = [];
    const idLength = pagingIds.length;

    for (let i = 0; i < Math.ceil(idLength / divide); i++) {
      const j = i * divide;
      // i*cnt 番目から i*cnt+cnt 番目まで取得
      const p = pagingIds.slice(j, j + divide);
      const Id = p[p.length - Number(p.length)];
      pagingIdArray[i] = Id;
    }
    return pagingIdArray;
  }

  getNowDateTimeString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const nowDateTime = year + '/' + month + '/' + day + ' ' + hour + ':' + minute;
    return nowDateTime;
  }
  /**
     * YYYYMM値から1日の日付を取得
     * @param int $yearMonth   201902
     * @return 
     */
  getDateByYearmonth(yearMonth: number) {
    let year: number = Math.floor(yearMonth / 100);
    let month: number = yearMonth % 100;
    return new Date(year, month - 1);

  }

  /**
     * array_columnのobject版
     * @param array $rows   Modelのgetの結果一覧
     * @param string $columnName 列名
     * @return array 列の値一覧
     */
  objectColumn(rows: any, columnName: string): any[] {
    let values = [];
    for (let i = 0; i < rows.length; i++) {

      let row = rows[i];
      values.push(row[columnName]);
    }
    return values;
  }

  /**
      * rowsからディクショナリを生成
      * @param array $rows   Modelのgetの結果一覧
      * @param string $keyFieldName キーとなる値が格納されている列名
      * @param string $valueFieldName 値の列名 nullのときはrowが値となる
      * @return array ディクショナリ
      * 
      */
  objectDictionary(rows: any, keyFieldName: string, valueFieldName: string): any {
    var dict: { [index: string]: object; } = {};
    for (let i = 0; i < rows.length; i++) {

      let row = rows[i];
      let key: string = String(row[keyFieldName]);


      let value;
      if (valueFieldName) {
        value = row[valueFieldName];
      } else {
        value = row;
      }
      let values;
      if (dict[key]) {
        //  すでに値が設定済み
        values = dict[key];
      } else {
        values = [];
      }
      values.push(value);

      //  値を設定
      dict[key] = values;
    }
    return dict;
  }

  formatDate(date: Date, day: number = 0): string {
    if (day == 0) {
      day = date.getDate();
    }
    return String(date.getFullYear()) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + day).slice(-2);

  }

  /**
   * 最大値まで値をカウントアップ
   * 最大値に到達しているときは０
   * @param currentValue 現在の値
   * @param maxValue 最大値
   */
  toggleValue(currentValue: number, maxValue: number) {
    if (currentValue < maxValue) {
      currentValue++;
    } else {
      currentValue = 0;
    }
    return currentValue;
  }

}
