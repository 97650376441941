import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../modules/auth/index';
import { UserProfileService } from '../../../value/index';

import { ShiftService } from '../../shifts/shift.service';
import { ShiftConst } from '../../shifts/shift.const';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { ShiftClassModel } from 'app/models/shift-class.model';
import { forEach } from '@angular/router/src/utils/collection';
import { TransportationExpenseModel } from 'app/models/transportation-expense.model';

@Component({
  selector: 'partner-lp-confirm',
  templateUrl: './lp-confirm.component.html',
  providers: [AuthService, PdfService],
  styleUrls: ['./lp-confirm.component.scss']
})
export class LpConfirmComponent implements OnInit {
  public partners: PartnerModel[];
  public tentativeShifts: TentativeShiftModel[];
  public schoolShiftCounts: SchoolShiftCountModel[];
  public currentPartner: PartnerModel;
  public mode: string;   //  'partner'固定
  public transportationExpense: number;   //  交通費
  public totalShiftCount: number;   // 合計勤務日数
  public isConfirmed: boolean;     //  シフト確認済みかどうか

  constructor(
    private userProfile: UserProfileService,
    private auth: AuthService,
    private router: Router,
    public shiftService: ShiftService,
    private pdfService: PdfService,
    public shiftConst: ShiftConst,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit() {
    this.shiftService.title = 'Proposed Shift';

    this.mode = this.userProfile.partner_staff == 'true' ? 'staff' : 'partner';

    //  翌月を設定
    let date = new Date();

    //  TODO: for debug
    //    date.setMonth(date.getMonth() - 1);

    let day = date.getDate();
    if (day >= 10) {
      // 10日以降は翌月
      date.setDate(1);
      date.setMonth(date.getMonth() + 1);
    }
    this.shiftService.setSelectedDate(date);


    this.getCurrentPartner();

  }
  onLogout() {
    if (confirm('Do you log out？')) {
      // ログアウト処理
      this.logout();
    }
  }

  logout() {
    this.auth.logoutAsPartner();
    this.userProfile.resetUserProfile();
  }

  getCurrentPartner(): void {

    this.shiftService.getPartnersForPartner(this.userProfile.id).subscribe(rows => {

      if (rows.length > 0) {
        this.currentPartner = rows[0];
        this.getLpConfirmShiftInfo(this.userProfile.id)

      }

    });
  }

  getLpConfirmShiftInfo(partnerId: number): void {

    this.isConfirmed = null;

    this.shiftService.getLpConfirmShiftInfoForPartner(partnerId, this.mode).subscribe(info => {
      let rows: TentativeShiftModel[];
      rows = info.lpConfirmShiftInfo;
      this.isConfirmed = info.isConfirmed;
      if (rows.length > 0) {
        this.createTentativeShifts(rows);
      } else {
        this.tentativeShifts = new Array();

      }
    });
  }


  captureScreen() {
    this.pdfService.export('shift.pdf', document.getElementById('main-content'));
  }

  isFilledUp(shiftClassInfos: ShiftClassModel[], timeNumber: number): boolean {
    for (let i = 0; i < shiftClassInfos.length; i++) {
      if (shiftClassInfos[i].timeNumber == timeNumber) {
        return shiftClassInfos[i].isFilledUp;
      }
    }
    return false;
  }


  /**
   * pdfダウンロード
   */
  onPdfDownload() {
    (async () => {
      // fetchでアクセス.
      let downloadUrl = this.shiftService.getTentativeShiftDownloadPdfUrl(this.currentPartner.partner_id, this.mode);
      const res = await fetch(downloadUrl);
      // Blob形式でデータ取得.
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);

      let partner = this.currentPartner;

      let title = "Proposed_Shift_" + this.shiftService.selectedDate.getFullYear() + `0${this.shiftService.selectedDate.getMonth() + 1}`.slice(-2) + '_' + partner.partner_fname_e + '_' + partner.partner_lname_e;

      // aタグを作成して無理やりクリック -> ダウンロード機能発火
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = title;
      a.click();
      window.URL.revokeObjectURL(url);
    })();
  }





  /**
   * 仮シフトが入っていなくても、一月分表示するために空のTentativeShiftを追加
   * @param rows 仮シフト一覧
   */
  createTentativeShifts(rows: TentativeShiftModel[]) {


    this.tentativeShifts = new Array();
    let shiftDate = new Date(this.shiftService.selectedDate);
    shiftDate.setDate(1);
    let month = shiftDate.getMonth()
    while (1) {
      let shiftCheck = this.findTentativeShiftByDate(shiftDate, rows);

      if (shiftCheck == null) {
        shiftCheck = new TentativeShiftModel();
        shiftCheck.date = new Date(shiftDate);
        shiftCheck.schoolName = '';
        shiftCheck.shiftClassInfos = new Array();
        for (let i = 0; i < this.shiftConst.shiftTimes.length; i++) {
          let shiftClassModel = new ShiftClassModel();
          shiftClassModel.isFilledUp = false;
          shiftCheck.shiftClassInfos.push(shiftClassModel);
        }
      }
      this.tentativeShifts.push(shiftCheck);


      shiftDate.setDate(shiftDate.getDate() + 1); //  翌日にする
      if (month != shiftDate.getMonth()) {
        //  月が変わったので終了
        break;
      }
    }
  }

  findTentativeShiftByDate(date: Date, tentativeShifts: TentativeShiftModel[]) {

    let day = date.getDate();
    for (let i = 0; i < tentativeShifts.length; i++) {
      let tentativeShift = tentativeShifts[i];
      let shiftCheckDay = new Date(tentativeShift.date).getDate();

      if (day == shiftCheckDay) {
        console.log(date);

        return tentativeShift;
      }
      if (day < shiftCheckDay) {
        return null;
      }
    }
    return null;
  }

  getClassName() {
    return 'success';
  }


  onConfirmShift() {
    if (confirm('Are you sure to confirm these shifts?')) {

      this.shiftService.confirmShiftForPartner(this.currentPartner.partner_id, this.mode).subscribe(info => {
        this.isConfirmed = info.isConfirmed;
      });

    }


  }


}
