import { Injectable } from '@angular/core';

// vexのセットアップ
const vex = require('vex-js');
vex.registerPlugin(require('vex-dialog'));
vex.defaultOptions.className = 'vex-theme-wireframe';
vex.defaultOptions.contentClassName = 'vex-custom';
vex.dialog.buttons.YES.text = 'はい';
vex.dialog.buttons.NO.text = 'いいえ';

/**
 * モーダル関連のサービスを提供するクラス
 */
@Injectable()
export class ModalService {

  constructor() { }

  show(message: string, callback: any) {
    // ダイアログ表示のインスタンスをリターン
    return vex.dialog.confirm({
      message: message,
      callback: function (value) {
        if (value) {
          return callback('ok');
        } else {
          return callback('cancel');
        }
      },
    });
  }
}
