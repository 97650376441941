import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { CommonComponentsModule } from '../../components/common-components.module';

@NgModule({
  imports: [CommonModule, CommonComponentsModule],
  declarations: [HomeComponent],
  exports: [HomeComponent],
})
export class HomeModule { }
