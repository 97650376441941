import { SchoolModel } from "./school.model";
import { HolidayModel } from "./holiday.model";
import { CloseSchoolDateModel } from "./close-chool-date.model";


export class ConfirmInfoModel {
    shifts: ConfirmShiftInfoModel[];     //  ConfirmShiftInfoのarray

    //  休日
    holidays: HolidayModel[];   //  mst_holidayのarray

    //  休校日
    closedays: CloseSchoolDateModel[];  //  close_school_dateのarray
}

export class ConfirmShiftInfoModel {
    school: SchoolModel;      //  パートナーID
    booths: BoothShift[];    //  ブースシフト情報の配列(BoothShiftのarray)
}


export class BoothShift {
    boothNumber: number;      //  ブース番号(number)
    dateToPartners: any;    //  日付ごとのコンシェルジュ配列(日付をキー、PartnerForConfirmを値とする連想配列)
    dateToCloseBooth: any;    //  ブースクローズしている日付をキー、ブース番号を値とする連想配列

    isDragover: boolean[];  // 全体確認画面で ブースごとのドラッグ状態（背景設定用） confirm.componentのoninitで初期化
}

/**
 * 全シフト確認画面用のコンシェルジュ情報
 */
export class PartnerForConfirm {
    partnerId: number;      //  パートナーID(number)
    partnerName: string;    //  パートナー名(string)
    backgroundColor: string;    //  背景色（クリックで切り替わる）

    isDragover: boolean;  // 全体確認画面で 一時退避領域用のドラッグ状態（背景設定用） 退避領域に初回ドロップ時に初期化
}

