export class DragDropService {
    private dragStartIndex: any;         //  ドラッグ開始したアイテムのインデックス
    private dragStartIndex2: number;      //  ドラッグ開始したアイテムのインデックス2
    private dragStartIndex3: number;      //  ドラッグ開始したアイテムのインデックス3
    private droppedIndex: any;           //  ドロップ先のインデックス
    private droppedIndex2: number;           //  ドロップ先のインデックス2
    private droppedIndex3: number;           //  ドロップ先のインデックス3

    constructor(index: any, index2: number = 0, index3: number = 3) {
        this.dragStartIndex = index;
        this.dragStartIndex2 = index2;
        this.dragStartIndex3 = index3;
    }

    /**
     * ドロップされたかどうか
     */
    isDropped(): boolean {
        return this.droppedIndex == null ? false : true;
    }

    /**
     * ドロップ
     * @param index ドロップ先のインデックス
     */
    dropped(index: any, index2: number = 0, index3: number = 3) {
        this.droppedIndex = index;
        this.droppedIndex2 = index2;
        this.droppedIndex3 = index3;
    }

    getDragStartIndex(): any {
        return this.dragStartIndex;
    }
    getDragStartIndex2(): number {
        return this.dragStartIndex2;
    }
    getDragStartIndex3(): number {
        return this.dragStartIndex3;
    }
    getDragStartSubIndex(): number {
        return this.droppedIndex2;
    }
    getDroppedIndex(): any {
        return this.droppedIndex;
    }
    getDroppedIndex2(): number {
        return this.droppedIndex2;
    }
    getDroppedIndex3(): number {
        return this.droppedIndex3;
    }

}
