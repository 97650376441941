import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShiftsRoutingModule } from './shifts-routing.module';
import { SchoolSettingComponent } from './school-setting/school-setting.component';
import { InitComponent } from './init/init.component';
import { MenuComponent } from './menu/menu.component';
import { SchoolInitComponent } from './school-init/school-init.component';
import { CheckComponent } from './check/check.component';
import { TentativeComponent } from './tentative/tentative.component';
import { TentativeConfirmComponent } from './tentative-confirm/tentative-confirm.component';
import { LpConfirmComponent } from './lp-confirm/lp-confirm.component';
import { NeedComponent } from './need/need.component';
import { BlockComponent } from './block/block.component';
import { ShiftsComponent } from './shifts.component';
import { CommonComponentsModule } from '../../components/common-components.module';
import { MyDatePickerModule } from 'mydatepicker';
import { ReleaseComponent } from './release/release.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { LpSelectModalComponent } from './modals/lp-select-modal/lp-select-modal.component';
import { MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NonApprovalListComponent } from './non-approval-list/non-approval-list.component';
import { PartnerSettingComponent } from './partner-setting/partner-setting.component';
import { RangePipe } from 'app/pipes/range.pipe';
import { ReleasePartnerComponent } from './release-partner/release-partner.component';
import { StaffHolidayComponent } from './staff-holiday/staff-holiday.component';
import { ConciergeSettingComponent } from './concierge-setting/concierge-setting.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmTransporExpenseComponent } from './confirm-transpor-expense/confirm-transpor-expense.component';
import { MemoListComponent } from './memo-list/memo-list.component';

@NgModule({
  imports: [
    CommonModule,
    ShiftsRoutingModule,
    CommonComponentsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    SimpleModalModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    SchoolSettingComponent,
    InitComponent,
    MenuComponent,
    SchoolInitComponent,
    CheckComponent,
    TentativeComponent,
    TentativeConfirmComponent,
    LpConfirmComponent,
    NeedComponent,
    BlockComponent,
    ShiftsComponent,
    ReleaseComponent,
    LpSelectModalComponent,
    NonApprovalListComponent,
    PartnerSettingComponent,
    RangePipe,
    ReleasePartnerComponent,
    StaffHolidayComponent,
    ConciergeSettingComponent,
    ConfirmComponent,
    ConfirmTransporExpenseComponent,
    MemoListComponent,
  ],
  entryComponents: [
    LpSelectModalComponent,
  ],
})
export class ShiftsModule { }
