import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../modules/auth/index';
import { UserProfileService } from '../../value/index';
import { ModalService } from '../../components/modal/index';

@Component({
  moduleId: module.id,
  selector: 'app-component-header',
  providers: [AuthService, ModalService],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private loginFlag: boolean;
  private displayProperty: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    public userProfile: UserProfileService,
    private modal: ModalService
  ) {
  }

  ngOnInit() {

  }

  onLogout() {
    if (confirm('ログアウトしますか？')) {
      // ログアウト処理
      this.logout();
    }
  }
  // モーダルサービス実行
  openModal() {
    this.displayProperty = 'none'
    let self = this;
    this.modal.show('ログアウトしますか？', function (result) {
      // [はい]なら
      if (result === 'ok') {
        // 何らか処理実行
        self.logout();
        self.displayProperty = '';
      } else {
        self.displayProperty = '';
      }
    });
  }

  logout() {
    this.auth.logout();
    this.userProfile.resetUserProfile();
  }

}
