import { Injectable } from '@angular/core';
import * as jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';

/**
 * pdf関連処理を提供するクラス
 */
@Injectable()
export class PdfService {

    /**
     * 
     * @param fileName pdfファイル名
     * @param element dom要素
     */
    public export(fileName, element) {
        html2canvas(element).then(canvas => {

            var contentWidth = canvas.width;
            var contentHeight = canvas.height;

            var pageHeight = contentWidth / 592.28 * 841.89;
            var leftHeight = contentHeight;
            var position = 0;
            var imgWidth = 595.28;
            var imgHeight = 592.28 / contentWidth * contentHeight;

            var pageData = canvas.toDataURL('image/jpeg', 1.0);

            var pdf = new jspdf('', 'pt', 'a4');

            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }

            pdf.save(fileName);
            // // Few necessary setting options
            // const imgWidth = 208;
            // const pageHeight = 295;
            // const imgHeight = canvas.height * imgWidth / canvas.width;
            // const heightLeft = imgHeight;

            // const contentDataURL = canvas.toDataURL('image/png')
            // const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
            // const position = 0;
            // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
            // pdf.save(fileName); // Generated PDF
        });

    }

}

