import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PartnerComponent } from './partner.component';
import { LoginComponent } from './login/login.component';
import { LpConfirmComponent } from './lp-confirm/lp-confirm.component';
import { PartnerLoginCheck } from '../../modules/auth/partner_login_check';


const routes: Routes = [
  {
    path: 'partner',
    component: PartnerComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'lp-confirm', component: LpConfirmComponent, canActivate: [PartnerLoginCheck] },
    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PartnerRoutingModule { }
