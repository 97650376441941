import { environment } from '../../environments/environment';

// API
export const apiBase = environment.apiBase;

/**
 * apiEndpoint
 * API エンドポイント設定
*/
export let apiEndpoint = {
    // ログインAPI
    loginApi: apiBase + '/login',
    // パートナーログインAPI
    loginPartner: apiBase + '/login_partner',
    // ユーザーステータス取得
    getUserStatus: apiBase + '/user/status/',
    // ユーザーの保持ポイント取得
    getUserPoints: apiBase + '/user/points/',
    // ニュースの一覧
    getNewsLists: apiBase + '/news/lists',
    // ホーム画面に表示する直近のレッスン一覧の取得
    getRecentlyLesson: apiBase + '/lesson/lists/recently',
    // ホーム画面に表示する直近レッスンコメント一覧の取得
    getRecentlyLessonComments: apiBase + '/lesson/comment/lists/recently',
    // レッスン履歴画面に表示する過去のレッスン履歴一覧
    getLessonHistory: apiBase + '/lesson/history/lists',
    // レッスン履歴画面に表示するレッスン日付一覧取得
    getLessonHistoryDateList: apiBase + '/lesson/history/date/list',
    // レッスン履歴の詳細
    getLessonHistoryDetail: apiBase + '/lesson/history/detail/',
    // 明日のレッスンのキャンセル履歴を確認
    getTomorrowLessonCancelHistory: apiBase + '/lesson/history/tomorrow/',
    // レッスンの予約
    postLessonBook: apiBase + '/lesson/book',
    // レッスンの予約キャンセル
    postLessonCancel: apiBase + '/lesson/cancel',
    // キャンセル可能なレッスンの一覧取得
    getLessonCancel: apiBase + '/lesson/cancel/lists/',
    // 当月の予約済レッスン件数を取得
    getLessonCountThisMonth: apiBase + '/lesson/count/recently/',
    // クイズのタイトル一覧を取得
    getQuizTitleLists: apiBase + '/quiz/lists',
    // クイズの回答済数を取得
    getQuizAnswerCorrectCount: apiBase + '/quiz/count/answer/correct/',
    // クイズの詳細を取得
    getQuizDetail: apiBase + '/quiz/detail',
    // クイズの履歴を送信
    postQuizHiatory: apiBase + '/quiz/history',
    // パートナーリストの取得
    getPartnerLists: apiBase + '/lesson/partner/',
    // スクール一覧の取得
    getSchoolLists: apiBase + '/lesson/school/lists',
    // シフト一覧の取得
    getShiftLists: apiBase + '/lesson/shift/lists/',
    // パートナー情報の取得
    getPartnerInfo: apiBase + '/partner/profile/',

    //  スクールのREST
    schools: apiBase + '/schools',
    //  スクール設定のREST
    schoolSettings: apiBase + '/school_settings',
    //  パートナーのREST
    partners: apiBase + '/partners',
    //  休日のREST
    holidays: apiBase + '/holidays',
    //  school_init/{YYYYMM}/{school_id}
    schoolInit: apiBase + '/school_init',
    //  shift_check/{YYYYMM}/{partner_id}
    shiftCheck: apiBase + '/shift_check',
    //  memo/{YYYYMM}/{partner_id}
    memo: apiBase + '/memo',

    //  memos/{YYYYMM}
    memos: apiBase + '/memos',

    //  仮シフト情報覧を取得 shift_class/{YYYYMM}/{school_id}
    shiftClass: apiBase + '/shift_class',

    //  スクール開催日一覧を取得　　school_days/{YYYYMM}/{school_id}
    schoolDays: apiBase + '/school_days',

    //  仮シフト情報覧を取得 tentative_shifts/?type=chank_info&school_id={school_id}&day={day}
    tentativeShifts: apiBase + '/tentative_shifts',

    //  need情報を取得 need/{YYYYMM}/{school_id}
    needInfo: apiBase + '/need_info',

    //  release機能
    shiftRelease: apiBase + '/shifts',

    //  admin シフト日付機能 admin_shift_dates/{school_id}/{partner_id} putパラメータに dateとis_set
    adminShiftDates: apiBase + '/admin_shift_dates',

    partner_partners: apiBase + '/partner/partners',

    partner_tentativeShifts: apiBase + '/partner/tentative_shifts',

};
