import { SchoolModel } from "./school.model";
import { SchoolShiftCountModel } from "./school-shift-count.model";
import { TransportationExpenseModel } from "./transportation-expense.model"

export class PartnerModel {
    partner_id: number;                  //  ID
    partner_status: string;              //  スクールカラー1
    partner_lname_j: string;              //  パートナー姓（日本語）
    partner_fname_j: string;              //  パートナー名（日本語）
    partner_lname_e: string;              //  パートナー姓（英語）
    partner_fname_e: string;              //  パートナー名（英語）
    partner_staff: boolean;               //  コンシェルジュのときはtrue
    partner_photo: string;                //  
    email_for_shift: string;              //  シフト通知用のパートナーemail
    schools: SchoolModel[];               //  勤務可能なスクール一覧
    key_possessions: SchoolModel[];       //  鍵保有しているスクール一覧（コンシェルジュのときのみ設定される）
    transportation_expense: TransportationExpenseModel[];    //  交通費一覧
    red_holidays: string[];               //  コンシェルジュの赤休日一覧（2019-07-01, 2019-07-02 とか日付の配列）コンシェルジュ休暇一覧用
    blue_holidays: string[];              //  コンシェルジュの青休日一覧（2019-07-01, 2019-07-02 とか日付の配列）コンシェルジュ休暇一覧用

    schoolShiftCounts: SchoolShiftCountModel[];     //  パートナーのスクールごとのシフト日数情報（パートナーリリース画面のときにサーバーから取得する）
    is_released: boolean;                           //  すでにリリース済みパートナーかどうか（パートナーリリース画面のときにサーバーから取得する）
    is_selected_for_release: boolean;                           //  リリース対象かどうか（パートナーリリース画面のときにUIで設定される）

    totalTransportationExpense: number;     //  交通費合計（交通費確認画面用）
    totalShiftCount: number;        //  一ヶ月の合計勤務日数（交通費確認画面用）
    holiday_weeks: number[];             //  コンシュルジュの定休曜日一覧　（コンシュルジュ設定での曜日用）

    schedule_dates: string[];       //  パートナーがシフトリクエストしている日付

    last_shift_date: string;   //  パートナーが先月シフトリクエストした日付
    is_confirmed: boolean;              //  パートナーがシフト確認済みかどうか

    get partnerName(): string {
        return PartnerModel.partnerName(this);
    }

    static partnerName(model: any): string {

        if (model.partner_staff) {
            return model.partner_lname_j + ' ' + model.partner_fname_j;
        } else {
            return model.partner_fname_e + ' ' + model.partner_lname_e;
        }
    }

    /**
   * 休日かどうか調べる
   * @param model パートナー
   * @param date 日付（2017-07-01とか）
   */
    static isRedHoliday(model: any, date: string) {
        return this.isHoliday(model.red_holidays, date);
    }
    static isBlueHoliday(model: any, date: string) {
        return this.isHoliday(model.blue_holidays, date);
    }
    static isHoliday(holidays: string[], date: string) {
        if (Array.isArray(holidays)) {
            return holidays.indexOf(date) != -1 ? true : false;
        } else {
            return false;
        }
    }

    /**
     * 休日設定を更新
     * @param model パートナーモデル
     * @param date 日付
     * @param isHoliday 休日かどうか
     */
    static updateHoliday(model: PartnerModel, date: string, isHoliday: boolean): PartnerModel {

        if (isHoliday) {
            //  赤休日に設定
            model.red_holidays = this.set_holiday(model.red_holidays, date);
        } else {
            //  休日解除
            model.red_holidays = this.reset_holiday(model.red_holidays, date);
            model.blue_holidays = this.reset_holiday(model.blue_holidays, date);
        }

        return model;
    }

    static reset_holiday(holidays: string[], date: string) {
        if (Array.isArray(holidays)) {
            let index = holidays.indexOf(date);

            //  休日解除する場合
            if (index != -1) {
                //  休日一覧から削除
                holidays.splice(index, 1);
            }
        }
        return holidays;
    }

    /**
     * 休日設定　
     * 手動の場合は赤休日にする
     */
    static set_holiday(holidays: string[], date: string) {
        if (Array.isArray(holidays)) {
            let index = holidays.indexOf(date);

            if (index == -1) {
                //  休日一覧に追加
                holidays.push(date);
            }
        }
        return holidays;
    }


    /**
     * 定休曜日を更新
     * @param model 
     * @param week 
     * @param isHoliday 
     */
    static updateStaffRegularHolidayWeek(model: PartnerModel, week: number, isHoliday: boolean): PartnerModel {
        let holidays = model.holiday_weeks;
        if (Array.isArray(holidays)) {
            let index = holidays.indexOf(week);

            if (isHoliday) {
                //  休日設定する場合
                if (index == -1) {
                    //  休日一覧に追加
                    holidays.push(week);
                    model.holiday_weeks = holidays;
                }
            } else {
                //  休日解除する場合
                if (index != -1) {
                    //  休日一覧から削除
                    holidays.splice(index, 1);
                    model.holiday_weeks = holidays;
                }
            }
        }
        return model;

    }

}
