import { Injectable } from '@angular/core';

/**
 * 処理中表示を提供するクラス
 */
@Injectable()
export class ProgressService {


    private isLoading: boolean;

    constructor() {
        this.isLoading = false;
    }


    /**
     * 処理中表示開始
     */
    start() {
        // setTimeoutで設定しないと、次のエラーになってしまう。  Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'.
        setTimeout(() => this.isLoading = true);
    }
    /**
     * 処理中表示終了
     */
    done() {
        this.isLoading = false;
    }
}
