import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {

  public dates: Date[];
  public selectedDate: Date;
  constructor(
    private router: Router,
    private shiftService: ShiftService,
  ) {
    this.shiftService.title = '月別初期設定';
    this.shiftService.showMenuLink = true;
  }

  ngOnInit() {

    this.initDates();
  }

  initDates(): void {
    this.dates = [];
    for (let i = 0; i < 6; i++) {

      let date = new Date();
      //  一月前から
      //      date.setMonth(date.getMonth() + i - 1);
      //  TODO: for debug ３ヶ月前から
      date.setDate(1);
      date.setMonth(date.getMonth() + i - 3);
      this.dates.push(date);
    }
    this.selectedDate = this.dates[0];

  }

  public selectMonth(): void {

    let month = this.selectedDate.getMonth();
    console.log(month);

    this.shiftService.selectedDate = this.selectedDate;

    this.router.navigate(["/shifts/menu"]);
  }






}
