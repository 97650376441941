import { Injectable, OnInit } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserProfileService } from '../../value/index';
import { AuthService } from './auth.service';

@Injectable()
export class LoginCheck implements CanActivate, OnInit {


  constructor(
    private auth: AuthService,
    private userProfile: UserProfileService,
  ) {
  }

  ngOnInit() {
  }

  /*
  async canActivate() {
    // ログイン状態を確認してtokenから情報の復元が行えれば復元(主に画面リロード時に利用)
    await this.auth.loginCheck(this.loginToken);
    // 画面の遷移は行うので必ずtrueでリターン
    return await true;
  }
  */
  async canActivate() {
    let loggedIn = await this.userProfile.getUserProfile('loggedIn');
    console.log(loggedIn);
    // ログイン状態を確認してtokenから情報の復元が行えれば復元(主に画面リロード時に利用)
    await this.auth.loginCheck(loggedIn);
    // 画面の遷移は行うので必ずtrueでリターン
    return await true;
  }

  public isLoggedIn() {
    return this.userProfile.getUserProfile('loggedIn');
  }

}
