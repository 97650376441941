import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


import { Subscription } from 'rxjs';

import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { SchoolModel } from 'app/models/school.model';
import { UserProfileService } from 'app/value/index';

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.scss'],
  providers: [PdfService]
})
export class ReleaseComponent implements OnInit {
  public shiftInfosForDaysForSchools: any;   //  スクールごと、日付ごとの仮シフト情報
  public schools: SchoolModel[];       // スクール一覧
  public currentSchool: SchoolModel;
  public mode: string;   //  'staff'のときは、コンシェルジュのシフト

  constructor(
    private router: Router,
    public shiftService: ShiftService,
    private pdfService: PdfService,
    private activatedRoute: ActivatedRoute,
    public userProfile: UserProfileService,
  ) {
    this.shiftService.showMenuLink = true;
    this.mode = activatedRoute.snapshot.queryParams['mode'];
    if (this.isStaffMode()) {
      this.shiftService.title = 'コンシェルジュシフトリリース最終確認';

    } else {
      this.shiftService.title = 'シフトリリース最終確認';
    }

  }

  // ngOnInit() {
  //   this.getAllTentativeShiftWithInfos();
  // }


  ngOnInit() {
    this.getSchools();

  }
  isStaffMode(): boolean {
    return this.mode == 'staff' ? true : false;
  }

  /**
   * スクール一覧を取得
   */
  getSchools(): void {

    this.shiftService.getSchools(this.mode, null).subscribe(schools => {

      if (schools.length > 0) {
        this.schools = schools;

        this.currentSchool = schools[0];

        this.getTentativeShiftWithInfos();

      }

    });
  }
  /**
   * スクール変更時
   * シフト情報を取得
   * 
   * @param value 
   */
  onChangeSchool(value: number) {


    this.currentSchool = this.schools[value];

    this.getTentativeShiftWithInfos();
  }

  /**
   * シフト情報を取得
   */
  getTentativeShiftWithInfos(): void {

    this.shiftService.getTentativeShiftWithInfos(this.currentSchool.school_id, this.mode).subscribe(rows => {

      if (rows) {
        this.shiftInfosForDaysForSchools = rows;
      }

    });
  }

  /**
   * シフト情報を取得
   */
  getAllTentativeShiftWithInfos(): void {

    this.shiftService.getAllTentativeShiftWithInfos().subscribe(rows => {

      if (rows) {
        this.shiftInfosForDaysForSchools = rows;
      }

    });
  }

  captureScreen() {
    this.pdfService.export(this.currentSchool.school_name_j + '_シフトリリース最終確認.pdf', document.getElementById('main-content'));
  }

  onReleaseA() {
    this.onRelease(1);
  }
  onReleaseB() {
    this.onRelease(2);
  }
  onRelease(partnerGroup) {
    let dateString = this.shiftService.selectedDate.getFullYear() + '年' + `0${this.shiftService.selectedDate.getMonth() + 1}`.slice(-2) + '月';
    let shiftTitle = dateString + '分の' + (partnerGroup == 1 ? 'A' : 'B') + 'シフト';
    if (confirm(shiftTitle + 'をリリースします。本当によろしいですか？')) {

      this.shiftService.getShiftCount(partnerGroup).subscribe(row => {

        let doRelease = true;
        if (row && row.count > 0) {

          if (confirm(shiftTitle + 'はすでに設定済みです。シフトを上書きしてよろしいですか？')) {

            doRelease = true;
          } else {
            doRelease = false;
          }

        }

        if (doRelease) {

          //  リリース処理
          this.shiftService.postShiftRelease(partnerGroup).subscribe(row => {

            if (row) {
              let count = row.count;
              this.shiftService.successMessage = shiftTitle + 'を' + count + '件設定しました';
            } else {
              this.shiftService.errorMessage = shiftTitle + 'リリースに失敗しました';

            }

          });

        }


      });




    }
  }


  isSuperAdmin(): boolean {
    return this.userProfile.isSuperAdmin();
  }

}
