import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { ChankInfoModel } from 'app/models/chank-info.model';
import { ShiftCheckModel, NonApprovalModel } from 'app/models/shift-check.model';

@Component({
  selector: 'app-non-approval-list',
  templateUrl: './non-approval-list.component.html',
  styleUrls: ['./non-approval-list.component.scss']
})
export class NonApprovalListComponent implements OnInit {
  //  private chankInfos: ChankInfoModel[];
  public days: Date[];                 // 日付一覧
  private currentDay: Date;             // 選択中の日付
  public nonApprovals: NonApprovalModel[];       //  非承認情報
  public showRejected: boolean;        //  非承認を表示
  public showRestOfAssinged: boolean;  //  ハーフを表示
  public showNotAssigned: boolean;     //  未アサインを表示
  private invalidDay: Date;



  constructor(
    private router: Router,
    public shiftService: ShiftService,

  ) {
    this.shiftService.title = '非承認シフト確認機能';
    this.shiftService.showMenuLink = true;

    this.showRejected = true;        //  非承認を表示
    this.showRestOfAssinged = true;  //  ハーフを表示
    this.showNotAssigned = true;     //  未アサインを表示
    this.invalidDay = new Date(0);

  }

  ngOnInit() {
    this.getAllSchoolDays();

  }

  /**
   * スクールの開講日一覧を取得
   */
  getAllSchoolDays(): void {

    this.shiftService.getAllSchoolDays().subscribe(days => {

      if (days.length > 0) {
        days.unshift(this.invalidDay, null);
        this.days = days;

        // this.currentDay = days[0];

        // //  非承認チャンク情報
        // this.getNonApprovalChankinfos(this.currentDay);

      }

    });
  }

  onChangeDay(value: Date) {

    console.log(value);

    if (value != (new Date(0))) {
      this.currentDay = value;
      this.getNonApprovalChankinfos(this.currentDay);

    }

  }


  getNonApprovalChankinfos(day: Date): void {

    if (day == null || day == this.invalidDay) {
      return;
    }

    this.shiftService.getNonApprovalChankinfos(day).subscribe(rows => {

      if (rows.length > 0) {

        this.nonApprovals = rows;
      } else {
        this.nonApprovals = null;
      }

    });
  }



}
