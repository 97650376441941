
/**
 * need情報
 */
export class NeedInfoModel {
    date: string;              //  日付
    schoolId: number;        //  スクールID
    firstTimeNumber: number; //  最初のタイム時間番号
    timeCount: number;       //  needのクラス数（最初のタイム時間番号から連続するクラス数）
}

export class DateNeedInfosModel {
    date: string;
    needInfos: NeedInfoModel[];         //  日付に対応するneed情報（ブースごと）
}
