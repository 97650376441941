import { ShiftClassModel } from 'app/models/shift-class.model';

/**
 * ・仮シフト作成と削除時
 * ・レッスンパートナー個別の仮シフト確認時
 */
export class TentativeShiftModel {
    method: string;         //  'create' / 'delete'
    schoolId: number;       //  スクールID
    date: Date;             //  日付
    partnerId: number;      //  パートナーID
    boothNumber: number;    //  ブース番号
    timeNumbers: number[]; //  タイム時間番号配列
    schoolName: string;     //  スクール名（レッスンパートナー個別の仮シフト確認のとき格納される）
    schoolColor: string;
    shiftClassInfos: ShiftClassModel[];      //  クラスの仮シフト状況（レッスンパートナー個別の仮シフト確認・ブロック機能のとき格納される）
    partnerName: string;     //  講師名（ブロック機能のとき格納される）
    mode: string;           //  コンシェルジュシフト処理のときは　'staff'になる
    isAdminShift: boolean;  //  LPのときAdminシフトのときはtrue
    isBlocked: number;     // ブロック設定用。 0:ブロックされていない、1:BLOCK、2:TTP

}
