import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShiftService } from '../shift.service';
import { PartnerModel } from 'app/models/partner.model';
import { UserProfileService } from 'app/value/index';

@Component({
  selector: 'app-release-partner',
  templateUrl: './release-partner.component.html',
  styleUrls: ['./release-partner.component.scss']
})
export class ReleasePartnerComponent implements OnInit {

  public partnerGroup: number;       // 1:Aクラス、2:Bクラス
  public partners: PartnerModel[];   //  グループのパートナー一覧 
  public is_all_selected_for_release: boolean;
  public mode: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public shiftService: ShiftService,
    private userProfile: UserProfileService,
  ) {
    this.partnerGroup = activatedRoute.snapshot.params['partnerGroup'];
    this.mode = activatedRoute.snapshot.queryParams['mode'];
    if (this.isStaffMode()) {
      this.shiftService.title = 'コンシェルジュリリース';

    } else {
      this.shiftService.title = (this.partnerGroup == 1 ? 'A' : 'B') + 'パートナーリリース';
    }
    this.shiftService.showMenuLink = true;
    this.is_all_selected_for_release = false;

  }
  isStaffMode(): boolean {
    return this.mode == 'staff' ? true : false;
  }
  /**
     * パートナー名を取得
     * @param model パートナー
     */
  partnerName(model: any) {
    return PartnerModel.partnerName(model);
  }

  ngOnInit() {
    this.getSchoolShiftCountsByPartnerGroup();

    if (!this.isSuperAdmin()) {
      this.shiftService.errorMessage = 'リリース権限がありません';
      return;
    }

  }
  getSchoolShiftCountsByPartnerGroup(): void {

    this.shiftService.getSchoolShiftCountsByPartnerGroup(this.partnerGroup, this.mode).subscribe(rows => {

      if (rows.length > 0) {
        this.partners = rows;
      }


    });
  }


  onRelease() {

    if (!this.isSuperAdmin()) {
      this.shiftService.errorMessage = 'リリース権限がありません';
      return;
    }

    let selectedPartners = new Array();
    for (let i = 0; i < this.partners.length; i++) {
      let partner = this.partners[i];
      if (partner.is_selected_for_release) {
        selectedPartners.push(partner);
      }
    }

    if (selectedPartners.length > 0) {
      let names: string[] = selectedPartners.map(function (partner, index, array) { return partner.partner_fname_e + ' ' + partner.partner_lname_e; });
      let dateString = this.shiftService.selectedDate.getFullYear() + '年' + `0${this.shiftService.selectedDate.getMonth() + 1}`.slice(-2) + '月';
      let shiftTitle = dateString + '分の' + names.join(',') + 'のシフト';
      if (confirm(shiftTitle + 'をリリースします。本当によろしいですか？')) {
        //  リリース処理
        this.shiftService.postShiftReleaseByPartners(selectedPartners, this.mode).subscribe(row => {

          if (row) {
            this.shiftService.successMessage = shiftTitle + 'をリリースしました';
          } else {
            this.shiftService.errorMessage = shiftTitle + 'リリースに失敗しました';

          }

          this.getSchoolShiftCountsByPartnerGroup();


        });

      }

    }





  }

  /**
   * パートナー全選択のチェック
   */
  onChangeCheckAllPartner() {
    this.is_all_selected_for_release = !this.is_all_selected_for_release;

    this.partners.forEach(partner => {

      if (!partner.is_released) {
        partner.is_selected_for_release = this.is_all_selected_for_release;
      }

    });

  }
  isSuperAdmin(): boolean {
    return this.userProfile.isSuperAdmin();
  }


}
