import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-component-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor() {}

}
