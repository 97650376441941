import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PartnerModel } from 'app/models/partner.model';
import { UserProfileService } from 'app/value/index';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  private partners: PartnerModel[];

  constructor(
    private router: Router,
    private shiftService: ShiftService,
    public datePipe: DatePipe,
    private userProfile: UserProfileService,
  ) {
    this.shiftService.title = '作成中シフト ' + this.datePipe.transform(this.shiftService.selectedDate, "yyyy/M");
    this.shiftService.showMenuLink = false;

  }

  ngOnInit() {
  }



  onSendConfirmMailAll(isUpdated: boolean) {
    if (confirm('全パートナーに仮シフトの' + (isUpdated ? '確定' : '確認') + 'メールを送信します' + (isUpdated ? '(2回目以降)' : '(初回)') + '。本当によろしいですか？')) {

      if (!isUpdated) {
        this.postConfirmMail(isUpdated);

      } else {

        this.shiftService.getTentativeShiftPartners(null).subscribe(rows => {

          if (rows.length > 0) {
            let partnerIds: number[] = rows.map(function (partner, index, array) {
              return partner.partner_id;
            });

            this.postConfirmMail(isUpdated, partnerIds);

          }
        });

      };
    }
  }

  /**
   * 確認・確定メール送信
   * @param isUpdated 
   * @param partnerIds 
   */
  postConfirmMail(isUpdated, partnerIds = null) {
    this.shiftService.postTentativeShiftInfosConfirmMail(partnerIds, isUpdated).subscribe(result => {
      this.checkResult(result);
    });

  }

  /**
   * 前月の仮シフトをコピー
   */
  onCopyTentativeShifts() {

    let preDate = new Date(this.shiftService.selectedDate);
    preDate.setMonth(preDate.getMonth() - 1);
    let preDateText = this.datePipe.transform(preDate, "yyyy/M");

    if (confirm(preDateText + 'の仮シフト情報を元に今月の仮シフトを作成します。本当によろしいですか？')) {

      this.shiftService.postTentativeShiftAutoCreate(preDate, null).subscribe(result => {

        if (result) {
          if (result.length > 0) {
            this.shiftService.successMessage = '仮シフトを自動生成しました';
          } else {
            this.shiftService.errorMessage = '仮シフトは自動生成されませんでした';
          }
        } else {
          //  仮シフト作成処理に時間がかかりタイムアウトになっているかも
          //  強制的に処理中表示終了
          this.shiftService.progressDone();
        }

      });

    }

  }

  /**
     * コンシェルジュ用に前月の仮シフトをコピー
     */
  onCopyStaffTentativeShifts() {

    let preDate = new Date(this.shiftService.selectedDate);
    preDate.setMonth(preDate.getMonth() - 1);
    let preDateText = this.datePipe.transform(preDate, "yyyy/M");

    if (confirm(preDateText + 'のコンシェルジュ仮シフト情報を元に今月のコンシェルジュ仮シフトを作成します。本当によろしいですか？')) {

      this.shiftService.postTentativeShiftAutoCreate(preDate, 'staff').subscribe(result => {

        if (result) {
          if (result.length > 0) {
            this.shiftService.successMessage = 'コンシェルジュ仮シフトを自動生成しました';
          } else {
            this.shiftService.errorMessage = 'コンシェルジュ仮シフトは自動生成されませんでした';
          }
        } else {
          //  仮シフト作成処理に時間がかかりタイムアウトになっているかも
          //  強制的に処理中表示終了
          this.shiftService.progressDone();
        }

      });

    }

  }

  checkResult(result: any) {
    let noEmailPartners: PartnerModel[] = result['noEmailPartners'];
    let dispatchedPartners: PartnerModel[] = result['dispatchedPartners'];
    if (dispatchedPartners && dispatchedPartners.length > 0) {
      let names: string[] = dispatchedPartners.map(function (partner, index, array) { return partner.partner_fname_e + ' ' + partner.partner_lname_e; });
      this.shiftService.successMessage = '確認メールの送信リクエストを受け付けました。' + names.join(',');

    }
    if (noEmailPartners && noEmailPartners.length > 0) {
      let names: string[] = noEmailPartners.map(function (partner, index, array) { return partner.partner_fname_e + ' ' + partner.partner_lname_e; });
      this.shiftService.errorMessage = '確認メールの送信に失敗しました。メールアドレスが設定されていません。' + names.join(',');
    }

  }

  isSuperAdmin(): boolean {
    return this.userProfile.isSuperAdmin();
  }

  /**
  * 仮シフトの全削除
  */
  onDeleteAllTentativeShifts() {
    let selectedDate = new Date(this.shiftService.selectedDate);
    let selectedDateText = this.datePipe.transform(selectedDate, "yyyy/M");

    if (confirm(selectedDateText + 'の仮シフトをすべて削除します。本当によろしいですか？')) {
      this.shiftService.deleteAllTentathiveShifts('partner').subscribe(result => {

        if (result) {
          this.shiftService.successMessage = '仮シフトをすべて削除しました';
        } else {
          //  強制的に処理中表示終了
          this.shiftService.progressDone();
        }

      });
    }

  }
  onDeleteStaffAllTentativeShifts() {
    let selectedDate = new Date(this.shiftService.selectedDate);
    let selectedDateText = this.datePipe.transform(selectedDate, "yyyy/M");

    if (confirm(selectedDateText + 'のコンシュルジュの仮シフトをすべて削除します。本当によろしいですか？')) {
      this.shiftService.deleteAllTentathiveShifts('staff').subscribe(result => {

        if (result) {
          this.shiftService.successMessage = 'コンシェルジュ仮シフトをすべて削除しました';
        } else {
          //  強制的に処理中表示終了
          this.shiftService.progressDone();
        }

      });

    }

  }

}
