import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router } from '@angular/router';
import { SchoolModel } from 'app/models/school.model';
import { SchoolInitModel } from 'app/models/school-init.model';

@Component({
  selector: 'app-school-init',
  templateUrl: './school-init.component.html',
  styleUrls: ['./school-init.component.scss']
})
export class SchoolInitComponent implements OnInit {

  public schools: SchoolModel[];
  public schoolInits: SchoolInitModel[];
  private currentSchoolId: number;
  constructor(
    private router: Router,
    public shiftService: ShiftService,
  ) {
    this.shiftService.title = '休校日設定';
    this.shiftService.showMenuLink = true;
  }

  ngOnInit() {
    this.getSchools();
  }

  getSchools(): void {

    this.shiftService.getSchoolsWithCloseDateCount().subscribe(schools => {

      if (schools.length > 0) {
        this.schools = schools;

        this.currentSchoolId = schools[0].school_id;
        //  休校情報を取得
        this.loadCloseInfo(this.currentSchoolId);

      }

    });
  }

  onChange(value: number) {

    console.log(value);

    this.loadCloseInfo(value);
  }


  loadCloseInfo(schoolId: number) {

    this.currentSchoolId = schoolId;
    this.shiftService.getSchoolInits(schoolId).subscribe(rows => {

      if (rows.length > 0) {
        this.schoolInits = rows;

        //  休校日数を更新
        this.updateCloseDateCount();

      }

    });

  }

  onCloseSchoolChange(event: any, row: SchoolInitModel) {
    this.setCloseInfo(row);

  }

  /**
 * 値更新
 */
  setCloseInfo(row: SchoolInitModel): void {

    let isClose = row.is_close;

    this.shiftService.updateSchoolInit(this.currentSchoolId, row).subscribe(row => {

      if (row.length > 0) {
      } else {
        //  エラーになったので、チェックをもとに戻す
        row[0].is_close = isClose ? false : true;
      }

      //  休校日数を更新
      this.updateCloseDateCount();

    });

  }

  updateCloseDateCount(): void {

    let currentSchool = this.currentSchool(this.currentSchoolId);
    if (currentSchool) {
      currentSchool.close_date_count = this.closeDateCount();
    }
  }

  closeDateCount(): number {
    let closeCount = 0;
    for (let i = 0; i < this.schoolInits.length; i++) {
      let schoolInit = this.schoolInits[i];

      if (schoolInit.is_close) {
        closeCount++;
      }
    }
    return closeCount;

  }

  currentSchool(schoolId: number): SchoolModel {
    for (let i = 0; i < this.schools.length; i++) {

      let school = this.schools[i];

      if (school.school_id == this.currentSchoolId) {
        return school;
      }
    }
    return null;
  }

}
