import { Route } from '@angular/router';
import { HomeComponent } from './home.component';
import { LoginCheck } from '../../modules/auth/index';

export const HomeRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoginCheck]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [LoginCheck]
  },
];
