
export class TransferExpense {
    schoolId: number;
    partnerId: number;
    value: string;
}


export class TransferExpenseStack {
    private lastExpense: TransferExpense;

    private stack: TransferExpense[];

    constructor() {
        this.stack = [];
    }


    public push(value: string, schoolId: number, partnerId: number) {
        if (this.lastExpense) {

            if (this.lastExpense.schoolId == schoolId && this.lastExpense.partnerId == partnerId) {
                //  同じフィールドの値を更新
                this.lastExpense.value = value;
            } else {
                //  違うフィールド

                //  前回のフィールドの値を保存
                this.insertLastExpense();
            }
        }

        if (!this.lastExpense) {
            let expense = new TransferExpense();
            expense.partnerId = partnerId;
            expense.schoolId = schoolId;
            expense.value = value;
            this.lastExpense = expense;
        }
    }

    private insertLastExpense() {
        if (this.lastExpense) {
            this.stack.push(this.lastExpense);
            this.lastExpense = null;
        }
    }

    public pop() {
        if (this.lastExpense) {
            this.insertLastExpense();
        }

        return this.stack.pop();
    }
}
