import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { ShiftConst } from '../shift.const';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { ShiftClassModel } from 'app/models/shift-class.model';
import { forEach } from '@angular/router/src/utils/collection';
import { TransportationExpenseModel } from 'app/models/transportation-expense.model';
import { ConfirmInfoModel, ConfirmShiftInfoModel, PartnerForConfirm, BoothShift } from 'app/models/confirm-shift-info.model';
import { HolidayModel } from "app/models/holiday.model";
import { CloseSchoolDateModel } from "app/models/close-chool-date.model";
import { UtilService } from 'app/modules/util/util.service';
import { DragDropService } from '../drag-drop.service';
import { getLocaleDateTimeFormat } from '@angular/common';
import { ViewChild, ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  providers: [UtilService, PdfService]

})
export class ConfirmComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  @ViewChild('table2') table2: ElementRef;
  public mode: string;   //  'staff'固定

  public confirmShiftInfos: ConfirmShiftInfoModel[];
  //  休日
  public holidays: string[];   // mst_holidayに設定されているholiday_dateのarray
  //  休校日
  public dictClosedays: any;  // school_idをキー、休校日の配列を値とする連想配列

  public selectedMonthDays: number;  //  選択月の日数
  public selectedYear: number;  //  選択年
  public selectedMonth: number;  //  選択月
  public firstTableColumn: number; //  1つ目のテーブルの列数（日数）
  public backgroundColorStocks: string[] = ['#FF0000', '#00FFFF', '#FFFF00', '#00FF00', '#AA0000', '#00AAAA', '#AAAA00', '#00AA00', '#770000', '#007777', '#777700', '#007700'];
  public currentDragDropService: DragDropService;
  public isDragover: {};  //  ブースごとのドラッグ状態（背景設定用）

  public tmpPartners: PartnerForConfirm[];   //  パートナーの退避領域

  public spaceBackgroundColor: string; //  空白部分の背景色

  constructor(
    private router: Router,
    public shiftService: ShiftService,
    private pdfService: PdfService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute

  ) {
    this.mode = activatedRoute.snapshot.queryParams['mode'];
    this.shiftService.showMenuLink = true;
    this.shiftService.title = '全仮シフトの確認';

    this.currentDragDropService = null;

    this.tmpPartners = new Array();

    this.spaceBackgroundColor = '';


  }

  ngOnInit() {
    this.selectedYear = this.shiftService.selectedDate.getFullYear();
    this.selectedMonth = this.shiftService.selectedDate.getMonth();
    this.selectedMonthDays = this.shiftService.getSelectedMonthDays();
    this.firstTableColumn = 15;

    this.getConfirmShiftInfo();

    for (let i = 0; i < 12; i++) {
      this.tmpPartners.push(new PartnerForConfirm());
    }

  }

  getConfirmShiftInfo(): void {

    this.shiftService.getConfirmShiftInfo(this.mode).subscribe(info => {


      if (info) {
        this.confirmShiftInfos = info.shifts;
        this.initConfirmShiftInfos();
        this.holidays = this.utilService.objectColumn(info.holidays, 'holiday_date');
        this.dictClosedays = this.utilService.objectDictionary(info.closedays, 'school_id', 'date');


      }

    });
  }
  initConfirmShiftInfos() {
    this.confirmShiftInfos.forEach(confirmShiftInfo => {
      confirmShiftInfo.booths.forEach(booth => {
        booth.isDragover = new Array();
      });
    });
  }

  getConfirmPartnerNameByDay(day: number, dateToPartners: any): string {
    let date = this.shiftService.formatSelectedDateString(day);

    if (dateToPartners[date]) {
      let partners: PartnerForConfirm[] = dateToPartners[date];
      let names = partners.map((value, index) => { return value.partnerName; });
      return names.join(',');
    } else {
      return '';
    }
  }
  getConfirmPartnerNameForTmp(i: number): string {
    let partner = this.getPartnerForTmp(i);
    if (partner) {
      return partner.partnerName;
    } else {
      return '';
    }
  }

  getConfirmPartnerBackgroundColorByDay(day: number, dateToPartners: any): string {
    let partners: PartnerForConfirm[] = this.getPartnersForConfirmByDat(day, dateToPartners);
    if (partners) {
      //  とりあえず先頭のパートナーの背景色
      return partners[0].backgroundColor;
    } else {
      //  パートナー設定されていないときは、スペースの背景色
      return this.spaceBackgroundColor;
    }
  }

  getConfirmPartnerBackgroundColorByDayForTmp(i: number): string {

    let partner: PartnerForConfirm = this.getPartnerForTmp(i);

    if (partner) {
      //  パートナーの背景色
      return partner.backgroundColor;
    } else {
      return '';
    }
  }


  /**
   * パートナークリック
   * 同じパートナーの背景を変更
   * @param day 
   * @param dateToPartners 
   */
  onClickPartner(day: number, dateToPartners: any) {
    let partners: PartnerForConfirm[] = this.getPartnersForConfirmByDat(day, dateToPartners);
    if (partners) {
      let partner: PartnerForConfirm = partners[0];
      if (partner.backgroundColor) {
        //  色が設定されている場合は、色を白にする
        this.backgroundColorStocks.unshift(partner.backgroundColor);  //  色を返却
        this.updatePartnerBackgroundColor(partner.partnerId, '');
      } else {
        //  色を設定
        if (this.backgroundColorStocks.length > 0) {
          let color = this.backgroundColorStocks.shift();
          this.updatePartnerBackgroundColor(partner.partnerId, color);
        }
      }
    } else {
      //  パートナーが設定されていない。空白のとき
      if (this.spaceBackgroundColor) {
        //  空白の色が設定されているときは、色を白にする
        this.backgroundColorStocks.unshift(this.spaceBackgroundColor);  //  色を返却
        this.spaceBackgroundColor = '';
      } else {
        //  色を設定
        if (this.backgroundColorStocks.length > 0) {
          let color = this.backgroundColorStocks.shift();
          this.spaceBackgroundColor = color;
        }
      }
    }
  }
  onClickPartnerForTmp(i: number) {

  }

  /**
   * パートナーの色を設定
   * @param partnerId 
   * @param color 
   */
  updatePartnerBackgroundColor(partnerId: number, color: string) {
    for (let i = 0; i < this.confirmShiftInfos.length; i++) {
      let shiftInfo: ConfirmShiftInfoModel = this.confirmShiftInfos[i];
      for (let j = 0; j < shiftInfo.booths.length; j++) {
        let dateToPartners = shiftInfo.booths[j].dateToPartners;

        this.updatePartnerBackgroundColorForDateToPartners(partnerId, color, dateToPartners);
      }
    }
  }

  /**
   * パートナーの色を設定
   * @param partnerId 
   * @param color 
   * @param dateToPartners 
   */
  updatePartnerBackgroundColorForDateToPartners(partnerId: number, color: string, dateToPartners: any) {
    for (let day in dateToPartners) {
      let partners: PartnerForConfirm[] = dateToPartners[day];

      if (partners) {
        for (let i = 0; i < partners.length; i++) {
          let partner: PartnerForConfirm = partners[i]
          if (partner.partnerId === partnerId) {
            partner.backgroundColor = color;
          }
        }

      }
    }

  }


  getPartnersForConfirmByDat(day: number, dateToPartners: any): any {
    let date = this.shiftService.formatSelectedDateString(day);

    if (dateToPartners[date]) {
      return dateToPartners[date];
    } else {
      return null;
    }

  }

  /**
   * ローカルの変数上で、指定日付のパートナーを取得して削除
   * @param day 
   * @param dateToPartners 
   */
  getAndRemovePartnersForConfirmByDate(day: number, dateToPartners: any): any {
    let date = this.shiftService.formatSelectedDateString(day);
    if (dateToPartners[date]) {
      let partners = dateToPartners[date];
      dateToPartners[date] = null;
      return partners;
    } else {
      return null;
    }
  }

  setPartnersForConfirmByDat(day: number, dateToPartners: any, partners: any) {
    let date = this.shiftService.formatSelectedDateString(day);
    if (!dateToPartners[date]) {
      dateToPartners[date] = partners;
    }
  }


  captureScreen() {
    this.pdfService.export('全シフト確認.pdf', document.getElementById('main-content'));
  }

  isHoliday(day: number) {

    if (!this.holidays) {
      return false;
    }
    let date = new Date(this.selectedYear, this.selectedMonth, day);
    let week = date.getDay();

    if (week == 0 || week == 6) {
      return true;
    } else {

      let dateString = this.utilService.formatDate(this.shiftService.selectedDate, day);
      if (this.holidays.indexOf(dateString) != -1) {
        return true;
      }
    }

    return false;
  }

  getDateText(day: number) {
    let date = new Date(this.selectedYear, this.selectedMonth, day);
    let week = date.getDay();

    return '' + (this.selectedMonth + 1) + '/' + day + '(' + ["日", "月", "火", "水", "木", "金", "土"][week] + ')';
  }
  getDayText(day: number) {
    let date = new Date(this.selectedYear, this.selectedMonth, day);
    let week = date.getDay();
    return '' + day + '(' + ["日", "月", "火", "水", "木", "金", "土"][week] + ')';
  }

  isClose(schoolId: number, day: number) {

    let key: string = String(schoolId);
    if (this.dictClosedays[key]) {
      let closeDays = this.dictClosedays[key];
      let dateString = this.utilService.formatDate(this.shiftService.selectedDate, day);
      if (closeDays.indexOf(dateString) != -1) {
        return true;
      }

    }
    return false;
  }


  /**
   * ドラッグ開始
   * @param ev 
   * @param school_id 
   * @param boothIndex 
   * @param dayIndex 
   */
  dragstart(ev: any, confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number) {
    ev.dataTransfer.setData("text", ev.target.id);
    if (this.isPartner(confirmShiftInfo, boothIndex, dayIndex)) {
      this.currentDragDropService = new DragDropService(confirmShiftInfo, boothIndex, dayIndex);
    }
  }
  dragstartForTmp(ev: any, i: number) {
    ev.dataTransfer.setData("text", ev.target.id);
    if (this.isPartnerForTmp(i)) {
      this.currentDragDropService = new DragDropService(null, 0, i);
    }

  }

  dragover(ev: any, confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number) {
    ev.preventDefault();

    if (!this.isPartner(confirmShiftInfo, boothIndex, dayIndex) && !this.isClose(confirmShiftInfo.school.school_id, dayIndex + 1)) {
      this.dragoverSet(confirmShiftInfo, boothIndex, dayIndex);
    }
  }
  dragoverForTmp(ev: any, i: number) {
    ev.preventDefault();
    if (!this.isPartnerForTmp(i)) {
      this.dragoverSetForTmp(i);
    }

  }
  dragleave(ev: any, confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number) {
    ev.preventDefault();
    this.dragoverReset(confirmShiftInfo, boothIndex, dayIndex);
  }
  dragleaveForTmp(ev: any, i: number) {
    ev.preventDefault();
    this.dragoverResetForTmp(i);
  }


  /**
   * ドロップ先でのイベント
   * @param ev 
   * @param confirmShiftInfo 
   * @param boothIndex 
   * @param dayIndex 
   */
  drop(ev: any, confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number) {
    ev.preventDefault();

    if (this.currentDragDropService) {
      this.dragoverReset(confirmShiftInfo, boothIndex, dayIndex);

      if (!this.isPartner(confirmShiftInfo, boothIndex, dayIndex) && !this.isClose(confirmShiftInfo.school.school_id, dayIndex + 1)) {

        //  パラメーターを設定
        this.currentDragDropService.dropped(confirmShiftInfo, boothIndex, dayIndex);

        if (this.currentDragDropService.getDragStartIndex() == null) {
          //  退避領域からのドロップ

          // サーバーに生成通知
          this.postTentativeShiftInfosForCreate(this.currentDragDropService);
        } else {

          //  サーバーに移動通知
          this.postTentativeShiftInfosForMove(this.currentDragDropService);
        }

      }


      this.currentDragDropService = null;

    }

  }

  /**
   * 一時領域にドロップ
   * パートナーを生成して保存
   * @param ev 
   * @param i 
   */
  dropForTmp(ev: any, i: number) {
    ev.preventDefault();

    if (this.currentDragDropService) {
      this.dragoverResetForTmp(i);

      if (!this.isPartnerForTmp(i)) {
        //  領域が空いているので、退避領域に退避

        //  サーバーに削除通知
        this.postTentativeShiftInfosForDelete(this.currentDragDropService, i);

      }

      this.currentDragDropService = null;

    }

  }

  /**
   * ドラッグリセット
   * ドラッグ先の背景をもとに戻す
   */
  dragoverReset(confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number): void {
    if (this.currentDragDropService) {
      let booth: BoothShift = confirmShiftInfo.booths[boothIndex];
      booth.isDragover[dayIndex] = false;
    }
  }
  dragoverResetForTmp(i: number): void {
    let partnerForConfirm = this.getPartnerForTmp(i);
    if (partnerForConfirm) {
      partnerForConfirm.isDragover = false;
    }
  }

  /**
   * ドラッグ中の背景を設定
   * @param index ブースインデックス
   */
  dragoverSet(confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number): void {
    if (this.currentDragDropService) {
      let booth: BoothShift = confirmShiftInfo.booths[boothIndex];
      booth.isDragover[dayIndex] = true;
    }
  }
  dragoverSetForTmp(i: number): void {
    let partnerForConfirm = this.getPartnerForTmp(i);
    if (partnerForConfirm) {
      partnerForConfirm.isDragover = true;
    }
  }

  isDragover_fn(confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number) {
    let booth: BoothShift = confirmShiftInfo.booths[boothIndex];
    return booth.isDragover[dayIndex];

  }
  isDragoverForTmp(i: number) {
    return this.getPartnerForTmp(i).isDragover;
  }
  isPartnerForTmp(i: number) {
    return this.getPartnerForTmp(i).partnerId ? true : false;
  }

  getPartnerForTmp(i: number) {
    return this.tmpPartners[i];

  }


  isPartner(confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number) {

    return this.getPartners(confirmShiftInfo, boothIndex, dayIndex) ? true : false;

  }


  getPartners(confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex: number): PartnerForConfirm[] {

    let dateToPartners = confirmShiftInfo.booths[boothIndex].dateToPartners;
    return this.getPartnersForConfirmByDat(dayIndex + 1, dateToPartners);

  }

  toTentativeShiftParam(partner: PartnerForConfirm, confirmShiftInfo: ConfirmShiftInfoModel, boothIndex: number, dayIndex): TentativeShiftModel {
    let tentativeShiftModel = new TentativeShiftModel();
    tentativeShiftModel.partnerId = partner.partnerId;
    tentativeShiftModel.schoolId = confirmShiftInfo.school.school_id
    tentativeShiftModel.boothNumber = boothIndex + 1;
    tentativeShiftModel.date = this.shiftService.formatSelectedDate(dayIndex + 1);
    return tentativeShiftModel;
  }

  /**
   * 仮シフト移動
   */
  postTentativeShiftInfosForMove(currentDragDropService: DragDropService): void {


    this.shiftService.errorMessage = "";

    let confirmShiftInfo = currentDragDropService.getDragStartIndex();
    let boothIndex = currentDragDropService.getDragStartIndex2();
    let dayIndex = currentDragDropService.getDragStartIndex3();
    let partners = this.getPartners(confirmShiftInfo, boothIndex, dayIndex);
    if (partners) {
      let partner = partners[0];
      let fromTentativeShift: TentativeShiftModel = this.toTentativeShiftParam(partner, confirmShiftInfo, boothIndex, dayIndex);
      let toTentativeShift: TentativeShiftModel = this.toTentativeShiftParam(partner, currentDragDropService.getDroppedIndex(), currentDragDropService.getDroppedIndex2(), currentDragDropService.getDroppedIndex3());

      this.shiftService.postTentativeShiftInfosForMove(fromTentativeShift, toTentativeShift, this.mode).subscribe(info => {
        if (info) {

          if (info.errorMessage) {

            //  サーバー上でエラー発生
            this.openModal(info.errorMessage);

          } else {

            //  サーバー上で移動できたのでローカルも移動

            //  ドラッグスタート時のパートナーを取得
            let startConfirmShiftInfo = currentDragDropService.getDragStartIndex();
            let startBoothIndex = currentDragDropService.getDragStartIndex2();
            let startDayIndex = currentDragDropService.getDragStartIndex3();
            let startDateToPartners = startConfirmShiftInfo.booths[startBoothIndex].dateToPartners;
            let partners = this.getAndRemovePartnersForConfirmByDate(startDayIndex + 1, startDateToPartners);

            //  ドロップ先に設定
            let confirmShiftInfo = currentDragDropService.getDroppedIndex();
            let boothIndex = currentDragDropService.getDroppedIndex2();
            let dayIndex = currentDragDropService.getDroppedIndex3();
            let dateToPartners = confirmShiftInfo.booths[boothIndex].dateToPartners;
            this.setPartnersForConfirmByDat(dayIndex + 1, dateToPartners, partners);


          }

        }

      });
    }

  }


  /**
 * 仮シフト削除
 * 退避領域に移動したとき
 */
  postTentativeShiftInfosForDelete(currentDragDropService: DragDropService, tmpIndex: number): void {


    this.shiftService.errorMessage = "";

    let confirmShiftInfo = currentDragDropService.getDragStartIndex();
    let boothIndex = currentDragDropService.getDragStartIndex2();
    let dayIndex = currentDragDropService.getDragStartIndex3();
    let partners = this.getPartners(confirmShiftInfo, boothIndex, dayIndex);
    if (partners) {
      let partner = partners[0];
      let tentativeShift: TentativeShiftModel = this.toTentativeShiftParam(partner, confirmShiftInfo, boothIndex, dayIndex);
      tentativeShift.mode = 'staff';
      tentativeShift.method = 'delete';

      this.shiftService.postTentativeShiftInfos(tentativeShift).subscribe(info => {
        if (info) {

          if (info.errorMessage) {

            //  サーバー上でエラー発生
            this.openModal(info.errorMessage);

          } else {

            //  サーバー上で移動できたのでローカルも移動

            //  ドラッグスタート時のパートナーを取得
            let startConfirmShiftInfo = currentDragDropService.getDragStartIndex();
            let startBoothIndex = currentDragDropService.getDragStartIndex2();
            let startDayIndex = currentDragDropService.getDragStartIndex3();
            let startDateToPartners = startConfirmShiftInfo.booths[startBoothIndex].dateToPartners;
            let partners = this.getAndRemovePartnersForConfirmByDate(startDayIndex + 1, startDateToPartners);

            //  ドロップ先に設定
            let partner = partners[0];
            partner.isDragover = false;
            this.tmpPartners[tmpIndex] = partner;

          }

        }

      });
    }

  }

  /**
 * 仮シフト作成
 * 退避領域からシフトに移動したとき
 * 
 * currentDragDropServiceのstartindex3に退避領域のインデックス値
 * currentDragDropServiceのdroppedに移動先の日付
 */
  postTentativeShiftInfosForCreate(currentDragDropService: DragDropService): void {


    this.shiftService.errorMessage = "";

    let confirmShiftInfo = currentDragDropService.getDroppedIndex();
    let boothIndex = currentDragDropService.getDroppedIndex2();
    let dayIndex = currentDragDropService.getDroppedIndex3();
    let partner = this.getPartnerForTmp(currentDragDropService.getDragStartIndex3());
    let tentativeShift: TentativeShiftModel = this.toTentativeShiftParam(partner, confirmShiftInfo, boothIndex, dayIndex);
    tentativeShift.mode = 'staff';
    tentativeShift.method = 'create';

    this.shiftService.postTentativeShiftInfos(tentativeShift).subscribe(info => {
      if (info) {

        if (info.errorMessage) {

          //  サーバー上でエラー発生
          this.openModal(info.errorMessage);

        } else {

          //  サーバー上で移動できたのでローカルも移動

          let tmpIndex = currentDragDropService.getDragStartIndex3();
          //  ドラッグスタート時のパートナーを取得 (退避領域)
          let partner = this.getPartnerForTmp(tmpIndex);

          //  退避領域をクリア
          this.tmpPartners[tmpIndex] = new PartnerForConfirm();

          //  ドロップ先に設定
          let confirmShiftInfo = currentDragDropService.getDroppedIndex();
          let boothIndex = currentDragDropService.getDroppedIndex2();
          let dayIndex = currentDragDropService.getDroppedIndex3();
          let dateToPartners = confirmShiftInfo.booths[boothIndex].dateToPartners;
          this.setPartnersForConfirmByDat(dayIndex + 1, dateToPartners, [partner]);


        }

      }

    });

  }


  /**
     * モーダル表示
     * @param message メッセージ
     */
  openModal(message: string) {
    if (message) {

      console.debug(message);
      this.shiftService.errorMessage = message;

    }
  }

  /**
   * ページの更新
   */
  onPageUpdate() {
    this.getConfirmShiftInfo();
  }

  boarderBottom(bi: number, booths: number) {

    return ((bi + 1) == booths) ? 'solid 3px #000000' : 'solid 1px #F2F2F2';
  }

  /**
   * excell出力
   */
  onExportExcel() {

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const ws2: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table2.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');

    // ファイル保存
    XLSX.writeFile(wb, '全シフト確認.xlsx');

  }

  /**
   * ブースがクローズされているかどうか
   * @param day 
   * @param dateToCloseBooth 日付をキーとしたディクショナリ（日付が存在していればクローズされているということ）
   */
  isCloseBooth(day: number, dateToCloseBooth: any): boolean {

    let date = this.shiftService.formatSelectedDateString(day);

    if (dateToCloseBooth[date]) {
      return true;
    } else {
      return false;
    }
  }



}
