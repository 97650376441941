// rxjsのobservableの拡張
import 'rxjs/add/observable/throw';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
// ルーティング設定のimport
import { routes } from './app.routes';
import { HomeModule } from './pages/home/home.module';
import { LoginModule } from './pages/login/login.module';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';

import { HttpService } from './modules/http/index';
import { UserProfileService } from './value/index';
import { DataService, ProgressService } from './modules/util/index';
import { CommonComponentsModule } from './components/common-components.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { LoginCheck } from './modules/auth/login_check';
import { AuthService } from './modules/auth/index';
import { ShiftsModule } from './pages/shifts/shifts.module';
import { DatePipe } from '@angular/common';
import { PartnerLoginCheck } from './modules/auth/partner_login_check';
import { LoginNoCheck } from './modules/auth/login_no_check';
import { PartnerModule } from './pages/partner/partner.module';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonComponentsModule,
    BrowserModule,
    FormsModule,
    ShiftsModule,                 //  シフト機能
    PartnerModule,                 //  パートナー機能
    // ルーティングモジュール
    RouterModule.forRoot(routes, { useHash: true }),
    HttpModule,
    HttpClientModule,
    HomeModule,
    LoginModule,
    HeaderModule,
    FooterModule,
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
  ],
  providers: [HttpService, UserProfileService, DataService, LoginCheck, ProgressService, PartnerLoginCheck, LoginNoCheck, AuthService, DatePipe],
  bootstrap: [AppComponent],
})

export class AppModule { }
