import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shifts/shift.service';
import { UserProfileService } from 'app/value';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  constructor(
    public shiftService: ShiftService,
    public userProfile: UserProfileService,
  ) { }

  ngOnInit() {
  }
  onCloseSuccessAlert() {
    //  成功メッセージクリア
    this.shiftService.successMessage = '';
  }
  onCloseErrorAlert() {
    //  エラーメッセージクリア
    this.shiftService.errorMessage = '';
  }

}
