import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class ShiftConst {
    constructor() {
    }
    get shiftTimes(): string[] {
        return [
            '10:00',
            '10:45',
            '11:30',
            '12:15',
            '13:00',
            '13:45',
            '14:30',
            '15:15',
            '16:00',
            '16:45',
            '17:30',
            '18:15',
            '19:00',
            '19:45',
            '20:30',
            '21:15',
        ];
    }

}
