import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { ShiftConst } from '../shift.const';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { ShiftClassModel } from 'app/models/shift-class.model';
import { forEach } from '@angular/router/src/utils/collection';
import { TransportationExpenseModel } from 'app/models/transportation-expense.model';
@Component({
  selector: 'app-lp-confirm',
  templateUrl: './lp-confirm.component.html',
  styleUrls: ['./lp-confirm.component.scss'],
  providers: [PdfService]

})
export class LpConfirmComponent implements OnInit {
  public partners: PartnerModel[];
  public tentativeShifts: TentativeShiftModel[];
  public schoolShiftCounts: SchoolShiftCountModel[];
  public currentPartner: PartnerModel;
  public mode: string;   //  'staff'のときは、コンシェルジュのシフト
  public transportationExpense: number;   //  交通費
  public totalShiftCount: number;   // 合計勤務日数
  public isConfirmed: boolean;     //  シフト確認済みかどうか

  constructor(
    private router: Router,
    public shiftService: ShiftService,
    private pdfService: PdfService,
    public shiftConst: ShiftConst,
    private activatedRoute: ActivatedRoute

  ) {
    this.shiftService.showMenuLink = true;
    this.mode = activatedRoute.snapshot.queryParams['mode'];
    if (this.isStaffMode()) {
      this.shiftService.title = 'コンシェルジュ仮シフトの確認';

    } else {
      this.shiftService.title = '仮シフトの確認　レッスンパートナー個別';
    }

  }

  ngOnInit() {
    this.getPartners();

  }
  isStaffMode(): boolean {
    return this.mode == 'staff' ? true : false;
  }
  /**
   * パートナー名を取得
   * @param model パートナー
   */
  partnerName(model: any) {
    return PartnerModel.partnerName(model);
  }

  getPartners(): void {


    this.shiftService.getTentativeShiftPartners(this.mode).subscribe(rows => {

      if (rows.length > 0) {
        this.partners = rows;
        this.getSchoolShiftCounts(rows[0].partner_id);

      }

    });
  }

  onChange(value: number) {

    console.log(value);

    this.getSchoolShiftCounts(value);

  }
  getLpConfirmShiftInfo(partnerId: number): void {

    this.currentPartner = this.getPartner(partnerId);

    this.isConfirmed = null;

    this.shiftService.getLpConfirmShiftInfo(partnerId, this.mode).subscribe(info => {
      let rows: TentativeShiftModel[];
      rows = info.lpConfirmShiftInfo;
      this.isConfirmed = info.isConfirmed;
      if (rows.length > 0) {
        this.createTentativeShifts(rows);
      } else {
        this.tentativeShifts = new Array();

      }
    });
  }

  getSchoolShiftCounts(partnerId: number): void {

    this.shiftService.getSchoolShiftCounts(partnerId, this.mode).subscribe(rows => {

      if (rows.length > 0) {
        this.schoolShiftCounts = rows;
      }

      this.getLpConfirmShiftInfo(partnerId);

      //  交通費計算
      this.updateTransportationExpense();
    });
  }



  /**
   * 交通費と合計勤務日数を計算
   */
  updateTransportationExpense() {
    let totalExpense = 0;
    let totalShiftCount = 0;
    if (this.schoolShiftCounts && this.currentPartner) {

      //  バス代を追加
      let busExpense = this.getExpense(0, this.currentPartner.transportation_expense);

      for (let i = 0; i < this.schoolShiftCounts.length; i++) {
        let schoolShiftCount = this.schoolShiftCounts[i];
        //  このスクールまでの片道の交通費を取得
        let expense = this.getExpense(schoolShiftCount.id, this.currentPartner.transportation_expense);
        totalExpense = totalExpense + schoolShiftCount.count * (expense + busExpense) * 2;    //  日数分の往復の交通費を追加
        totalShiftCount = totalShiftCount + schoolShiftCount.count;

      }

    }
    this.transportationExpense = totalExpense;

    this.totalShiftCount = totalShiftCount;   //  合計勤務日数
  }

  /**
   * スクールの交通費を取得
   * @param id スクールID
   * @param rows 
   */
  getExpense(id: number, rows: TransportationExpenseModel[]) {
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];

        if (row.school_id == id) {
          return row.expense;
        }
      }

      return 0;
    }

  }



  captureScreen() {
    this.pdfService.export('仮シフトの確認.pdf', document.getElementById('main-content'));
  }

  isFilledUp(shiftClassInfos: ShiftClassModel[], timeNumber: number): boolean {
    for (let i = 0; i < shiftClassInfos.length; i++) {
      if (shiftClassInfos[i].timeNumber == timeNumber) {
        return shiftClassInfos[i].isFilledUp;
      }
    }
    return false;
  }


  /**
   * pdfダウンロード
   */
  onPdfDownload() {
    (async () => {
      // fetchでアクセス.
      let downloadUrl = this.shiftService.getTentativeShiftDownloadPdfUrl(this.currentPartner.partner_id, this.mode);
      const res = await fetch(downloadUrl);
      // Blob形式でデータ取得.
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);

      let partner = this.currentPartner;

      let title = "Proposed_Shift_" + this.shiftService.selectedDate.getFullYear() + `0${this.shiftService.selectedDate.getMonth() + 1}`.slice(-2) + '_' + partner.partner_fname_e + '_' + partner.partner_lname_e;

      // aタグを作成して無理やりクリック -> ダウンロード機能発火
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = title;
      a.click();
      window.URL.revokeObjectURL(url);
    })();
  }

  getPartner(partnerId: number) {
    //  パートナーを取得
    let partners: PartnerModel[] = this.partners.filter(partner => partner.partner_id == partnerId);
    return partners[0];
  }

  onSendConfirmMail() {
    if (confirm(this.currentPartner.partner_fname_e + ' ' + this.currentPartner.partner_lname_e + ' に仮シフトの確認メールを送信します。よろしいですか？')) {
      this.shiftService.postTentativeShiftInfosConfirmMail([this.currentPartner.partner_id], true).subscribe(result => {


        this.checkResult(result);

      });
    }
  }
  onSendConfirmMailAll() {
    if (confirm('全パートナーに仮シフトの確認メールを送信します。本当によろしいですか？')) {
      let partnerIds: number[] = this.partners.map(function (partner, index, array) {
        return partner.partner_id;
      });

      this.shiftService.postTentativeShiftInfosConfirmMail(partnerIds, false).subscribe(result => {


        this.checkResult(result);

      });
    }
  }

  checkResult(result: any) {
    let noEmailPartners: PartnerModel[] = result['noEmailPartners'];
    let dispatchedPartners: PartnerModel[] = result['dispatchedPartners'];
    if (dispatchedPartners && dispatchedPartners.length > 0) {
      let names: string[] = dispatchedPartners.map(function (partner, index, array) { return partner.partner_fname_e + ' ' + partner.partner_lname_e; });
      this.shiftService.successMessage = '確認メールの送信リクエストを受け付けました。' + names.join(',');

    }
    if (noEmailPartners && noEmailPartners.length > 0) {
      let names: string[] = noEmailPartners.map(function (partner, index, array) { return partner.partner_fname_e + ' ' + partner.partner_lname_e; });
      this.shiftService.errorMessage = '確認メールの送信に失敗しました。メールアドレスが設定されていません。' + names.join(',');
    }

  }


  /**
   * 仮シフトが入っていなくても、一月分表示するために空のTentativeShiftを追加
   * @param rows 仮シフト一覧
   */
  createTentativeShifts(rows: TentativeShiftModel[]) {


    this.tentativeShifts = new Array();
    let shiftDate = new Date(this.shiftService.selectedDate);
    shiftDate.setDate(1);
    let month = shiftDate.getMonth()
    while (1) {
      let shiftCheck = this.findTentativeShiftByDate(shiftDate, rows);

      if (shiftCheck == null) {
        shiftCheck = new TentativeShiftModel();
        shiftCheck.date = new Date(shiftDate);
        shiftCheck.schoolName = '';
        shiftCheck.shiftClassInfos = new Array();
        for (let i = 0; i < this.shiftConst.shiftTimes.length; i++) {
          let shiftClassModel = new ShiftClassModel();
          shiftClassModel.isFilledUp = false;
          shiftCheck.shiftClassInfos.push(shiftClassModel);
        }
      }
      this.tentativeShifts.push(shiftCheck);


      shiftDate.setDate(shiftDate.getDate() + 1); //  翌日にする
      if (month != shiftDate.getMonth()) {
        //  月が変わったので終了
        break;
      }
    }
  }

  findTentativeShiftByDate(date: Date, tentativeShifts: TentativeShiftModel[]) {

    let day = date.getDate();
    for (let i = 0; i < tentativeShifts.length; i++) {
      let tentativeShift = tentativeShifts[i];
      let shiftCheckDay = new Date(tentativeShift.date).getDate();

      if (day == shiftCheckDay) {
        console.log(date);

        return tentativeShift;
      }
      if (day < shiftCheckDay) {
        return null;
      }
    }
    return null;
  }

  getClassName() {
    return 'success';
  }


  onConfirmShift() {
    if (confirm('Are you sure to confirm these shifts?')) {

      this.shiftService.confirmShift(this.currentPartner.partner_id, this.mode).subscribe(info => {
        this.isConfirmed = info.isConfirmed;
      });

    }


  }
}

