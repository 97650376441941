import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { ShiftConst } from '../shift.const';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { ShiftClassModel } from 'app/models/shift-class.model';
import { forEach } from '@angular/router/src/utils/collection';
import { TransportationExpenseModel } from 'app/models/transportation-expense.model';
@Component({
  selector: 'app-confirm-transpor-expense',
  templateUrl: './confirm-transpor-expense.component.html',
  styleUrls: ['./confirm-transpor-expense.component.scss']
})
export class ConfirmTransporExpenseComponent implements OnInit {
  public partners: PartnerModel[];
  private mode: string;   //  'staff'のときは、コンシェルジュのシフト

  constructor(
    private router: Router,
    private shiftService: ShiftService,

  ) {
    this.shiftService.showMenuLink = true;
    this.mode = 'staff';
    this.shiftService.title = 'コンシェルジュ交通費の確認';

  }

  ngOnInit() {
    this.getPartners();

  }
  isStaffMode(): boolean {
    return this.mode == 'staff' ? true : false;
  }
  /**
   * パートナー名を取得
   * @param model パートナー
   */
  partnerName(model: any) {
    return PartnerModel.partnerName(model);
  }

  getPartners(): void {

    this.shiftService.getTransportExpenseForPartners(this.mode).subscribe(rows => {

      if (rows.length > 0) {
        this.partners = rows;

        this.updateTotalExpense();
      }

    });
  }

  updateTotalExpense() {
    for (let i = 0; i < this.partners.length; i++) {
      let partner = this.partners[i];

      this.calcTotalTransportationExpense(partner);

    }
  }



  /**
   * 交通費と勤務日数を計算
   */
  calcTotalTransportationExpense(partner: PartnerModel) {
    let totalShiftCount = 0;
    let totalExpense = 0;
    let schoolShiftCounts = partner.schoolShiftCounts;
    if (schoolShiftCounts && partner) {

      //  バス代(一ヶ月の定期代)
      let busExpense = this.getExpense(0, partner.transportation_expense);

      for (let i = 0; i < schoolShiftCounts.length; i++) {
        let schoolShiftCount = schoolShiftCounts[i];
        //  このスクールまでの片道の交通費を取得
        let expense = this.getExpense(schoolShiftCount.id, partner.transportation_expense);
        totalExpense = totalExpense + schoolShiftCount.count * expense * 2;    //  日数分の往復の交通費を追加

        totalShiftCount = totalShiftCount + schoolShiftCount.count;     //  勤務日数の合計を計算
      }

      //  バス代を追加
      totalExpense = totalExpense + busExpense;
    }

    partner.totalTransportationExpense = totalExpense;
    partner.totalShiftCount = totalShiftCount;

    return totalExpense;
  }

  /**
   * スクールの交通費を取得
   * @param id スクールID
   * @param rows 
   */
  getExpense(id: number, rows: TransportationExpenseModel[]) {
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];

        if (row.school_id == id) {
          return row.expense;
        }
      }

      return 0;
    }

  }

}

