import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { ScheduleMonthModel } from 'app/models/schedule-month.model';
import { PartnerModel } from 'app/models/partner.model';

@Component({
  selector: 'app-memo-list',
  templateUrl: './memo-list.component.html',
  styleUrls: ['./memo-list.component.scss']
})
export class MemoListComponent implements OnInit {

  public scheduleMonths: ScheduleMonthModel[];
  private mode: string;

  constructor(
    public shiftService: ShiftService,

  ) {
    this.shiftService.title = 'メモ一覧';
    this.shiftService.showMenuLink = true;
    this.mode = 'partner';

  }

  ngOnInit() {
    this.getScheduleMonths();

  }
  getScheduleMonths(): void {

    this.shiftService.getMemos().subscribe(rows => {

      if (rows.length > 0) {
        this.scheduleMonths = rows;
      }

    });
  }
  partnerName(model: ScheduleMonthModel): string {
    return PartnerModel.partnerName(model);
  }

}
