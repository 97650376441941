import { Injectable } from '@angular/core';

/**
 * データ処理を提供するクラス
 */
@Injectable()
export class DataService {

  private strage;

  constructor() {
    //   this.strage = sessionStorage;    //  セッションストレージのとき
    this.strage = localStorage;   //  ローカルストレージのとき
  }
  // セッションストレージに値をセット
  setSessionStorage(key: string, value: string): void {
    this.strage.setItem(key, value);
  }
  // セッションストレージから値を取得
  getSessionStorage(key: string): any {
    return this.strage.getItem(key);
  }
  // セッションストレージから値を削除
  deleteSessionStorage(key: string): void {
    this.strage.removeItem(key);
  }
  clear() {
    this.strage.clear();
  }
}
