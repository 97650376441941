import { Injectable } from '@angular/core';
import { SchoolModel } from '../../models/school.model';
import { SchoolSettingModel } from '../../models/school-setting.model';
import { PartnerModel } from '../../models/partner.model';
import { ChankInfoModel } from '../../models/chank-info.model';

import { SchoolInitModel } from '../../models/school-init.model';
import { HolidayModel } from '../../models/holiday.model';
import { ShiftCheckModel, NonApprovalModel } from '../../models/shift-check.model';
import { ScheduleMonthModel } from '../../models/schedule-month.model';

import { ShiftClassModel } from '../../models/shift-class.model';
import { TentativeShiftModel } from '../../models/tentative-shift.model';

import { Observable, of } from 'rxjs';
import { HttpService } from '../../modules/http/index';
import { catchError, map, tap } from 'rxjs/operators';
import { apiEndpoint } from '../../value/index';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';
import { NeedInfoModel } from 'app/models/need-info.model';
import { TentativeShiftConfirmModel } from 'app/models/tentative-shift-confirml.model';
import { ProgressService } from 'app/modules/util/progress.service';

import { ConfirmInfoModel } from '../../models/confirm-shift-info.model';

import { AuthService } from '../../modules/auth/index';
import { UserProfileService } from '../../value/index';


@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  private _title: String;                   //  タイトル
  private _selectedDate: Date;              //  選択中の年月
  private _showMenuLink: boolean;
  private schoolUrl = apiEndpoint.schools;  // Web APIのURL
  private schoolSettingUrl = apiEndpoint.schoolSettings;
  private partnerUrl = apiEndpoint.partners;
  private holidayUrl = apiEndpoint.holidays;
  private schoolInitUrl = apiEndpoint.schoolInit;
  private shiftCheckUrl = apiEndpoint.shiftCheck;
  private memoUrl = apiEndpoint.memo;
  private shiftClassUrl = apiEndpoint.shiftClass;
  private schoolDaysUrl = apiEndpoint.schoolDays;
  private _errorMessage: String;
  private _successMessage: String;
  private weekOffset: number;

  public weeks: String[] = ["日", "月", "火", "水", "木", "金", "土"];

  constructor(
    private http: HttpService,
    private ngProgress: ProgressService,
    private auth: AuthService,
    private userProfile: UserProfileService,
  ) {
    this._showMenuLink = true;
  }
  set showMenuLink(showMenuLink: boolean) {
    this._showMenuLink = showMenuLink;
  }
  get showMenuLink(): boolean {
    return this._showMenuLink;
  }


  set title(title: String) {
    this._title = title;
    this.successMessage = '';   //  成功メッセージクリア
    this.errorMessage = '';    //  エラークリア

  }
  get title(): String {
    return this._title;
  }
  set selectedDate(date: Date) {
    this.userProfile.setSelectedDate(date);
    this.setSelectedDate(date);

  }
  setSelectedDate(date: Date) {
    this._selectedDate = date;
    //  選択された月の1日の曜日を保存（０が日曜日） 　曜日テキストを取得できるようにするため
    this.weekOffset = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  }
  /**
 * 選択月の曜日テキストを取得
 * @param day 日付
 */
  public getJapaneseWeekText(day: number) {
    let dayOfWeek = (day - 1 + this.weekOffset) % 7;
    return this.weeks[dayOfWeek];

  }
  get selectedDate(): Date {
    if (!this._selectedDate) {
      this.setSelectedDate(this.userProfile.getSelectedDate());
    }
    return this._selectedDate;
  }
  set errorMessage(message: String) {
    this._errorMessage = message;
  }
  get errorMessage(): String {
    return this._errorMessage;
  }
  set successMessage(message: String) {
    this._successMessage = message;
  }
  get successMessage(): String {
    return this._successMessage;
  }
  private getYearMonthParam(date: Date): string {
    return String(date.getFullYear()) + ("0" + (date.getMonth() + 1)).slice(-2);
  }

  /**
   * 選択中の年月の日数を取得
   */
  public getSelectedMonthDays(): number {
    let year = this.selectedDate.getFullYear();
    let month = this.selectedDate.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };


  /**
   *  yyyy-mm-dd 形式で現在の選択年月に指定日をつけて返す
   */
  public formatSelectedDateString(day: number): string {
    let date: Date = this.selectedDate;
    return String(date.getFullYear()) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + day).slice(-2);

  }
  public formatSelectedDate(day: number): Date {
    let date: Date = this.selectedDate;
    return new Date(date.getFullYear(), this.selectedDate.getMonth(), day);
  }
  public formatNextMonthSelectedDate(day: number): Date {
    let date: Date = this.selectedDate;
    return new Date(date.getFullYear(), this.selectedDate.getMonth() + 1, day);
  }

  /**
  * スクール一覧を取得
  */
  public getSchools(mode: string, day: Date): Observable<SchoolModel[]> {

    let url = this.schoolUrl + '?mode=' + mode + '&day=' + day;
    this.ngProgress.start()
    return this.http.get<SchoolModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchools', [])),
      );
  }
  /**
   * スクール一覧を取得
   * 休校日数も取得
   */
  public getSchoolsWithCloseDateCount(): Observable<SchoolModel[]> {

    //  本社も取得したいので、modeはstaffにする
    let url = this.schoolUrl + '?option=close_date_count&mode=staff&year_month=' + this.getYearMonthParam(this.selectedDate);
    this.ngProgress.start()
    return this.http.get<SchoolModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchoolsWithCloseDateCount', [])),
      );
  }

  /**
   * スクール開講日一覧を取得
   * @param schoolId スクールID
   */
  public getSchoolDays(schoolId: number): Observable<Date[]> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = this.schoolDaysUrl + '/' + this.getYearMonthParam(this.selectedDate) + '/' + schoolId;
    this.ngProgress.start()
    return this.http.get<Date[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchoolDays', [])),
      );
  }
  /**
   * スクール開講日一覧を取得
   */
  public getAllSchoolDays(): Observable<Date[]> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = this.schoolDaysUrl + '/' + this.getYearMonthParam(this.selectedDate) + '/0';
    this.ngProgress.start()
    return this.http.get<Date[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getAllSchoolDays', [])),
      );
  }


  /**
  * スクール設定一覧を取得
  */
  public getSchoolSettings(): Observable<SchoolSettingModel[]> {

    let url = this.schoolSettingUrl;
    this.ngProgress.start()
    return this.http.get<SchoolSettingModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchoolSettings', [])),
      );
  }

  /**
  * パートナー一覧を取得
  */
  public getPartners(partner_id: number = null): Observable<PartnerModel[]> {

    let url = this.partnerUrl + (partner_id ? '?partner_id=' + partner_id : '');
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getPartners', [])),
      );
  }


  /**
   * 指定年月に仮シフトに入っている講師一覧を取得
   */
  public getTentativeShiftPartners(mode: string): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '?type=lp_confirm&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getTentativeShiftPartners', [])),
      );
  }

  /**
   * 全パートナーの指定年月の仮シフトによる交通費を取得
   */
  public getTransportExpenseForPartners(mode: string): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '?type=confirm_transpotation_expense&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getTransportExpenseForPartners', [])),
      );
  }

  /**
  * シフトリクエストしているパートナー（アサイン可能なLP）一覧を取得
  * 　　
  */
  public getShiftCheckPartners(): Observable<PartnerModel[]> {

    //  ?type=shift_check&day=2019-02-01
    let url = this.partnerUrl + '?type=shift_check&year_month=' + this.getYearMonthParam(this.selectedDate);
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getShiftCheckPartners', [])),
      );
  }

  /**
  * school_init情報一覧を取得
  */
  public getSchoolInits(schoolId: number): Observable<SchoolInitModel[]> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = this.schoolInitUrl + '/' + this.getYearMonthParam(this.selectedDate) + '/' + schoolId;
    this.ngProgress.start()
    return this.http.get<SchoolInitModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchoolInit', [])),
      );
  }



  /**
  * 休日一覧を取得
  */
  public getHolidays(): Observable<HolidayModel[]> {

    //  ***/201902 のように、年月をYYYYMM で表す
    let url = this.holidayUrl + '/' + this.getYearMonthParam(this.selectedDate);
    this.ngProgress.start()
    return this.http.get<HolidayModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getHolidays', [])),
      );
  }



  /**
* スクール設定更新
*/
  public updateSchoolSetting(schoolSetting: SchoolSettingModel): Observable<SchoolSettingModel[]> {

    let url = this.schoolSettingUrl + '/' + schoolSetting.school_id;

    this.ngProgress.start()
    return this.http.put<SchoolSettingModel[]>(url, JSON.stringify(schoolSetting))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateSchoolSetting', [])),
      );
  }


  /**
  * 休校情報更新
  */
  public updateSchoolInit(schoolId: number, schoolInit: SchoolInitModel): Observable<SchoolInitModel[]> {

    let url = this.schoolInitUrl + '/' + schoolId;

    this.ngProgress.start()
    return this.http.put<SchoolInitModel[]>(url, JSON.stringify(schoolInit))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateSchoolInit', [])),
      );
  }

  /**
   * メモを取得
   * @param partnerId 講師ID 
   */
  public getMemo(partnerId: number): Observable<String[]> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = this.memoUrl + '/' + this.getYearMonthParam(this.selectedDate) + '/' + partnerId;
    this.ngProgress.start()
    return this.http.get<String[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getMemo', [])),
      );
  }

  /**
   * メモ一覧を取得
   */
  public getMemos(): Observable<ScheduleMonthModel[]> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = apiEndpoint.memos + '/' + this.getYearMonthParam(this.selectedDate);
    this.ngProgress.start()
    return this.http.get<ScheduleMonthModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getMemos', [])),
      );
  }

  /**
   * シフトチェック一覧を取得
   * @param partnerId 講師ID 
   */
  public getShiftChecks(partnerId: number): Observable<ShiftCheckModel[]> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = this.shiftCheckUrl + '/' + this.getYearMonthParam(this.selectedDate) + '/' + partnerId;
    this.ngProgress.start()
    return this.http.get<ShiftCheckModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getShiftChecks', [])),
      );
  }

  /**
  * シフトチェック情報更新
  */
  public updateShiftCheck(partnerId: number, row: ShiftCheckModel): Observable<ShiftCheckModel[]> {

    let url = this.shiftCheckUrl + '/' + partnerId;

    this.ngProgress.start()
    return this.http.put<ShiftCheckModel[]>(url, JSON.stringify(row))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateShiftCheck', [])),
      );
  }

  /**
  * シフトリクエストのチャンクを変更
  */
  public updateChankForShiftCheck(partnerId: number, row: ShiftCheckModel): Observable<ShiftCheckModel[]> {

    let url = this.shiftCheckUrl + '/chank/' + partnerId;

    this.ngProgress.start()
    return this.http.put<ShiftCheckModel[]>(url, JSON.stringify(row))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateChankForShiftCheck', [])),
      );
  }

  /**
   * 仮シフト情報覧を取得
   * @param schoolId スクールID 
   */
  // public getShiftClassInfos(schoolId: number): Observable<any[]> {

  //   let date = this.selectedDate;
  //   //  ***/201902/500 のように、年月をYYYYMM で表す
  //   let url = this.shiftClassUrl + '/' + String(date.getFullYear()) + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + schoolId;
  //   this.ngProgress.start()
  //   return this.http.get<any[]>(url)
  //     .pipe(
  //       tap(_ => this.ngProgress.done()),
  //       catchError(this.handleError('getShiftClassInfos', [])),
  //     );
  // }
  /**
   * パートナー一覧を取得
   * @param name 講師名の一部 
   */
  public getPartnersByName(name: String): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '?type=candidate&name=' + name;
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getPartnersByName', [])),
      );
  }
  /**
   * 仮シフト情報覧を取得
   * @param schoolId スクールID 
   */
  public getTentativeShiftInfos(schoolId: number, day: Date, mode: string): Observable<any> {

    //  ***/201902/500 のように、年月をYYYYMM で表す
    let url = apiEndpoint.tentativeShifts + '?type=tentative&day=' + day + '&school_id=' + schoolId + '&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getTentativeShiftInfos', [])),
      );
  }

  /**
   * コンシェルジュ仮シフト情報覧を取得
   * @param schoolId スクールID 
   */
  /*
  public getStaffTentativeShiftInfos(schoolId: number, day: Date): Observable<any> {

    //  --/201902/500 のように、年月をYYYYMM で表す
    let url = apiEndpoint.tentativeShifts + '/?type=staff_tentative&day=' + day + '&school_id=' + schoolId;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getStaffTentativeShiftInfos', [])),
      );
  }
  */

  /**
  * 仮シフト移動
  */
  public postTentativeShiftInfosForMove(fromTentativeShift: TentativeShiftModel, toTentativeShift: TentativeShiftModel, mode: string): Observable<any> {

    let url = apiEndpoint.tentativeShifts;

    this.ngProgress.start()

    let params = {
      'method': 'move',
      'mode': mode,
      'fromTentativeShift': fromTentativeShift,
      'toTentativeShift': toTentativeShift,
    }


    return this.http.post<any>(url, JSON.stringify(params))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('postTentativeShiftInfos', [])),
      );
  }


  /**
    * 仮シフト作成 / 削除 / ブロック情報更新
    */
  public postTentativeShiftInfos(tentativeShiftModel: TentativeShiftModel): Observable<any> {

    let url = apiEndpoint.tentativeShifts;

    this.ngProgress.start()
    return this.http.post<any>(url, JSON.stringify(tentativeShiftModel))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('postTentativeShiftInfos', [])),
      );
  }
  /**
   * 仮シフト情報覧を取得
   * @param partnerId パートナーID 
   */
  public getLpConfirmShiftInfo(partnerId: number, mode: string): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '?type=lp_confirm&partner_id=' + partnerId + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getLpConfirmShiftInfo', [])),
      );
  }

  /**
   * パートナーによる仮シフトの確認
   * @param partnerId 
   * @param mode 
   */
  public confirmShift(partnerId: number, mode: string): Observable<any> {
    let url = apiEndpoint.tentativeShifts + '?type=lp_do_confirm_shifts&partner_id=' + partnerId + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('confirmShift', [])),
      );

  }

  /**
     * 全仮シフト情報覧を取得
     */
  public getConfirmShiftInfo(mode: string): Observable<any> {

    //  /tentative_shifts/
    let url = apiEndpoint.tentativeShifts + '?type=confirm&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getConfirmShiftInfo', [])),
      );
  }


  /**
   * NEED用に仮シフト情報覧を取得
   * @param day 日付 
   */
  public getTentativeShiftInfoForNeed(day: Date): Observable<TentativeShiftModel[]> {

    let url = apiEndpoint.tentativeShifts + '?type=need&day=' + day
    this.ngProgress.start()
    return this.http.get<TentativeShiftModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getTentativeShiftInfoForNeed', [])),
      );
  }

  /**
   * BLOCK機能用に仮シフト情報覧を取得
   * @param day 日付 
   */
  public getTentativeShiftWithBlockInfos(schoolId: Number, day: Date): Observable<TentativeShiftModel[]> {

    let url = apiEndpoint.tentativeShifts + '?type=block&day=' + day + '&school_id=' + schoolId
    this.ngProgress.start()
    return this.http.get<TentativeShiftModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getTentativeShiftWithBlockInfos', [])),
      );
  }

  /**
   * ブロック付きの仮シフト一覧を取得
   * 指定日付範囲の
   * すべてのスクールのすべての開校日が対象
   */
  public getAllTentativeShiftWithInfos(): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '?type=release&year_month=' + this.getYearMonthParam(this.selectedDate);
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getAllTentativeShiftWithInfos', [])),
      );
  }

  /**
   * ブロック付きの仮シフト一覧を取得
   * 指定日付範囲の
   * すべてのスクールのすべての開校日が対象
   */
  public getTentativeShiftWithInfos(schoolId: number, mode: string): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '?type=release&school_id=' + schoolId + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getTentativeShiftWithInfos', [])),
      );
  }

  /**
   * パートナーがアサインされているスクールごとのシフト日数を取得
   */
  public getSchoolShiftCounts(partnerId: number, mode: string): Observable<SchoolShiftCountModel[]> {

    let url = apiEndpoint.tentativeShifts + '?type=school_shift_count&partner_id=' + partnerId + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<SchoolShiftCountModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchoolShiftCounts', [])),
      );
  }


  /**
     * 非承認のチャンク情報一覧を取得
     * @param day 
     */
  public getNonApprovalChankinfos(day: any): Observable<NonApprovalModel[]> {

    let url: string;
    if (day === null || day === 'null') {
      url = this.shiftCheckUrl + '?type=non_approval&year_month=' + this.getYearMonthParam(this.selectedDate);

    } else {
      url = this.shiftCheckUrl + '?type=non_approval&day=' + day;
    }
    this.ngProgress.start()
    return this.http.get<NonApprovalModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getShiftChecks', [])),
      );
  }

  /**
   * 勤務可能なスクール一覧つきのパートナー一覧を取得
   */
  public getAllPartnerSchool(mode: string): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '?type=school&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getAllPartnerSchool', [])),
      );
  }

  public updatePartnerSchool(schoolId: number, partnerId: number, method: string): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '/' + partnerId + '?type=school&method=' + method;
    let data = JSON.parse('{"schoolId" : ' + schoolId + '}');
    this.ngProgress.start()
    return this.http.put<PartnerModel[]>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updatePartneSchool', [])),
      );
  }

  public updateKeyPosession(schoolId: number, partnerId: number, method: string): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '/' + partnerId + '?type=key_posession&method=' + method;
    let data = JSON.parse('{"schoolId" : ' + schoolId + '}');
    this.ngProgress.start()
    return this.http.put<PartnerModel[]>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateKeyPosession', [])),
      );
  }
  public updateTransportationExpense(schoolId: number, partnerId: number, expense: string): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '/' + partnerId + '?type=transportation_expense&expense=' + expense;
    let data = JSON.parse('{"schoolId" : ' + schoolId + '}');
    this.ngProgress.start()
    return this.http.put<PartnerModel[]>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateKeyPosession', [])),
      );
  }

  public updatePartnersEmail(partners: PartnerModel[]): Observable<PartnerModel[]> {

    let url = this.partnerUrl + '/0?type=email';

    let data: { [key: string]: PartnerModel[]; } = {};
    data['partners'] = partners;
    this.ngProgress.start()
    return this.http.put<PartnerModel[]>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updatePartnersEmail', [])),
      );
  }

  /**
   * スクールごとのneed情報を取得
   * @param schoolId スクールID
   */
  public getNeedInfo(schoolId: number): Observable<NeedInfoModel[]> {

    let url = apiEndpoint.needInfo + '/' + this.getYearMonthParam(this.selectedDate) + '/' + schoolId;
    this.ngProgress.start()
    return this.http.get<NeedInfoModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getNeedInfo', [])),
      );
  }



  public getTentativeShiftDownloadPdfUrl(partnerId: number, mode: string) {
    return apiEndpoint.tentativeShifts + '/lp_download_pdf/' + this.getYearMonthParam(this.selectedDate) + '/' + partnerId + '?mode=' + mode;

  }

  /**
  * 仮シフト　確認メール
  */
  public postTentativeShiftInfosConfirmMail(partnerIds: number[], isUpdated: boolean): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '/send_confirmmail';

    let model = new TentativeShiftConfirmModel();
    model.partnerIds = partnerIds;
    model.isUpdated = isUpdated;
    model.yearMonth = this.getYearMonthParam(this.selectedDate);

    this.ngProgress.start()
    return this.http.post<any>(url, JSON.stringify(model))
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('postTentativeShiftInfosConfirmMail', [])),
      );
  }

  public postShiftRelease(partnerGroup: number): Observable<any> {
    let url = apiEndpoint.shiftRelease + '/release';
    let data = JSON.parse('{"partnerGroup" : ' + partnerGroup + ', "yearMonth" : ' + this.getYearMonthParam(this.selectedDate) + '}');
    this.ngProgress.start()
    return this.http.post<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('postShiftRelease', [])),
      );

  }

  public getShiftCount(partnerGroup: number): Observable<any> {
    let url = apiEndpoint.shiftRelease + '/count/' + this.getYearMonthParam(this.selectedDate) + '/' + partnerGroup;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getShiftCount', [])),
      );

  }
  /**
     * パートナーグループを指定して、パートナーがアサインされているスクールごとのシフト日数を取得
     */
  public getSchoolShiftCountsByPartnerGroup(partnerGroup: number, mode: string): Observable<PartnerModel[]> {

    let url = apiEndpoint.tentativeShifts + '?type=school_shift_count&partner_group=' + partnerGroup + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getSchoolShiftCountsByPartnerGroup', [])),
      );
  }

  /**
   * パートナー指定してリリース
   * @param partners partnerModel[]
   */
  public postShiftReleaseByPartners(partners: PartnerModel[], mode: string): Observable<any> {

    let partnerIds: number[] = partners.map(function (partner, index, array) { return partner.partner_id });

    let url = apiEndpoint.shiftRelease + '/release_partners?mode=' + mode;

    let data = JSON.parse('{"partnerIds" : [], "yearMonth" : ' + this.getYearMonthParam(this.selectedDate) + '}');
    data.partnerIds = partnerIds;
    this.ngProgress.start()
    return this.http.post<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('postShiftReleaseByPartners', [])),
      );

  }


  /**
   * 仮シフトの自動生成
   * @param partners partnerModel[]
   */
  public postTentativeShiftAutoCreate(baseYearMonth: Date, mode: string): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '/create?mode=' + mode;

    let data = JSON.parse('{"type" : "auto", "baseYearMonth" : ' + this.getYearMonthParam(baseYearMonth) + ', "yearMonth" : ' + this.getYearMonthParam(this.selectedDate) + '}');
    this.ngProgress.start()
    return this.http.post<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('postTentativeShiftAutoCreate', [])),
      );

  }

  /**
   * 強制的に処理中表示終了
   */
  public progressDone() {
    this.ngProgress.done();

  }

  /**
   * コンシェルジュの休暇一覧を取得
   */
  public getAllStaffHolidays(): Observable<PartnerModel[]> {

    //  ***/201902 のように、年月をYYYYMM で表す
    let url = this.partnerUrl + '?type=staff_holidays&year_month=' + this.getYearMonthParam(this.selectedDate);
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getAllStaffHolidays', [])),
      );
  }

  /**
   * コンシェルジュ休暇設定
   * @param partnerId 
   * @param date 
   * @param action 0:休暇解除、1:休暇設定、2:休暇リセット（１月分） 
   * @param holiday_type 
   */
  public updateStaffHoliday(partnerId: number, date: string, action: number, holiday_type: number): Observable<any> {


    //  partners/100?type=staff_holidays
    let url = this.partnerUrl + '/' + partnerId + '?type=staff_holidays'

    let data = JSON.parse('{"partnerId" : ' + partnerId + ', "date" : "' + date + '", "action" : ' + action + ', "holiday_type" : ' + holiday_type + '}');
    this.ngProgress.start()
    return this.http.put<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateStaffHoliday', [])),
      );

  }

  /**
   * コンシェルジュの定休曜日を設定
   * @param partnerId コンシェルジュID
   * @param week      曜日番号　０：日曜日
   * @param isHoliday trueのとき定休日設定、falseのとき解除
   */
  public updateStaffRegularHolidayWeek(partnerId: number, week: number, isHoliday: boolean): Observable<any> {


    let url = this.partnerUrl + '/' + partnerId + '?type=staff_regular_holiday_weeks'

    let data = JSON.parse('{"partnerId" : ' + partnerId + ', "week" : "' + week + '", "isHoliday" : ' + isHoliday + '}');
    this.ngProgress.start()
    return this.http.put<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateStaffRegularHolidayWeek', [])),
      );

  }



  /**
  * Adminシフト日付情報更新
  */
  public updateAdminShiftDate(schoolId: number, partnerId: number, date: Date, isSet: boolean): Observable<any> {

    let url = apiEndpoint.adminShiftDates + '/' + schoolId + '/' + partnerId;

    let params = {};
    params['date'] = date;
    params['is_set'] = isSet;

    let data = JSON.stringify(params);
    this.ngProgress.start()
    return this.http.put<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateAdminShiftDate', [])),
      );
  }

  /**
    * 失敗したHttp操作を処理します。
    * アプリを持続させます。
    * @param operation - 失敗した操作の名前
    * @param result - observableな結果として返す任意の値
    */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // consoleに出力

      this.ngProgress.done()

      if (error.status) {
        if (error.status == 401) {
          this.auth.logout();
          return;

        }
      }


      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

  /**
   * テスト用に404エラーを発生させる
   */
  public test404Error(): Observable<any> {

    let url = this.partnerUrl + 'abc'
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('test404Error', [])),
      );
  }


  /**
   * 仮シフトの全削除
   * @param partners partnerModel[]
   */
  public deleteAllTentathiveShifts(mode: string): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '/delete_all?mode=' + mode;

    let data = JSON.parse('{"yearMonth" : ' + this.getYearMonthParam(this.selectedDate) + '}');
    this.ngProgress.start()
    return this.http.post<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('deleteAllTentathiveShifts', [])),
      );

  }


  /**
  * ブースのクローズ・オープン
  */
  public updateBoothOpenClose(schoolId: number, boothNumber: number, date: Date): Observable<any> {

    let url = apiEndpoint.tentativeShifts + '/booth_open_close/' + schoolId + '/' + boothNumber;

    let params = {};
    params['date'] = date;

    let data = JSON.stringify(params);
    this.ngProgress.start()
    return this.http.post<any>(url, data)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('updateAdminShiftDate', [])),
      );
  }

  /**
  * パートナーを取得
  */
  public getPartnersForPartner(partner_id: number): Observable<PartnerModel[]> {

    let url = apiEndpoint.partner_partners + '?partner_id=' + partner_id;
    this.ngProgress.start()
    return this.http.get<PartnerModel[]>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getPartnersForPartner', [])),
      );
  }

  public getLpConfirmShiftInfoForPartner(partnerId: number, mode: string): Observable<any> {
    let url = apiEndpoint.partner_tentativeShifts + '?type=lp_confirm&partner_id=' + partnerId + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('getLpConfirmShiftInfoForPartner', [])),
      );
  }
  /**
   * パートナーによる仮シフトの確認
   * @param partnerId 
   * @param mode 
   */
  public confirmShiftForPartner(partnerId: number, mode: string): Observable<any> {
    let url = apiEndpoint.partner_tentativeShifts + '?type=lp_do_confirm_shifts&partner_id=' + partnerId + '&year_month=' + this.getYearMonthParam(this.selectedDate) + '&mode=' + mode;;
    this.ngProgress.start()
    return this.http.get<any>(url)
      .pipe(
        tap(_ => this.ngProgress.done()),
        catchError(this.handleError('confirmShiftForPartner', [])),
      );

  }

}
