import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { ShiftConst } from '../shift.const';
import { Router } from '@angular/router';
import { PartnerModel } from 'app/models/partner.model';
import { ShiftCheckModel } from 'app/models/shift-check.model';
import { ChankInfoModel } from 'app/models/chank-info.model';
import { count } from 'rxjs/operators';
import { reject } from 'q';
import { empty } from 'rxjs';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { UtilService } from 'app/modules/util/util.service';
import { SchoolModel } from 'app/models/school.model';
import { forEach } from '@angular/router/src/utils/collection';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  providers: [UtilService]
})

export class CheckComponent implements OnInit {
  public currentPartnerId: number;
  public shiftChecks: ShiftCheckModel[];
  public partners: PartnerModel[];
  public originalPartners: PartnerModel[];

  public memo: String;

  public baseTimeNumbers: number[];
  public shiftTimes: string[];

  public requestCount: number;    // 　リクエスト数
  public approvalCount: number;   //  承認日数

  //  パートナーの仮シフト情報
  public tentativeShifts: TentativeShiftModel[];
  public mode: string;

  //  日付とtentativeShiftModelのdictionary
  public dateToTentativeShift: any;
  public schools: SchoolModel[];

  public isOverContinuousWork: boolean;      //  ６日以上の連続勤務をリクエストしているレッスンパートナーを取得
  public isOverContinuousHoliday: boolean;//  ７日以上の連続休暇をリクエストしているレッスンパートナーを取得
  public isOverWork: boolean;//  ２０日以上の勤務をリクエストしているレッスンパートナーを取得
  public isAllPartner: boolean;//  シフトリクエストしていないパートナーも選択対象とする場合はtrue(シフトリクエストをこの画面上で追加できるようにするため)
  public isNoPropose: boolean;//  リクエスト未提出のレッスンパートナーを取得
  public isNoConfirm: boolean;//  シフト未確認のレッスンパートナーを取得

  public schoolShiftCounts: SchoolShiftCountModel[];   //  指定パートナーがアサインされているスクールごとの日数
  public selectedPartnerName: string;  //  指定パートナー
  public schoolShiftTotalCount: number;

  constructor(
    private router: Router,
    public shiftService: ShiftService,
    public shiftConst: ShiftConst,
    public utilService: UtilService,

  ) {
    this.shiftService.title = '提出済みシフトの確認';
    this.shiftService.showMenuLink = true;
    this.shiftTimes = shiftConst.shiftTimes;
    this.mode = 'partner';
    this.isOverContinuousWork = false;
    this.isOverContinuousHoliday = false;
    this.isOverWork = false;
    this.isAllPartner = false;
    this.isNoPropose = false;
    this.isNoConfirm = false;

  }

  ngOnInit() {
    this.requestCount = 0;
    this.approvalCount = 0;
    this.getSchools();

  }

  getPartners(): void {

    this.shiftService.getShiftCheckPartners().subscribe(rows => {

      if (rows.length > 0) {
        this.originalPartners = rows;
        this.partners = rows;
        this.onChangeFilter();

        //        this.currentPartnerId = rows[0].partner_id;
        //  シフトリクエスト情報を取得
        //        this.getMemo(this.currentPartnerId);

      }

    });
  }

  onChangeContinuousWork(value: any) {
    this.isOverContinuousWork = value;
    this.onChangeFilter();
  }
  onChangeWork(value: any) {
    this.isOverWork = value;
    this.onChangeFilter();

  }
  onChangeContinuousHoliday(value: any) {
    this.isOverContinuousHoliday = value;
    this.onChangeFilter();

  }

  onChangeAllPartner(value: any) {
    this.isAllPartner = value;
    this.onChangeFilter();
  }
  onChangeNoPropose(value: any) {
    this.isNoPropose = value;
    this.onChangeFilter();

  }
  onChangeNoConfirm(value: any) {
    this.isNoConfirm = value;
    this.onChangeFilter();

  }


  onChangeFilter(): void {

    let isCurrentPartnerIdOK: boolean = false;


    let partners: PartnerModel[] = new Array();

    let originalPartners = this.originalPartners.slice();

    if (this.isNoPropose) {
      //  「未提出」のパートナーのみのとき
      let tmpOriginalPartners: PartnerModel[] = new Array();

      for (let i = 0; i < originalPartners.length; i++) {
        let partner = originalPartners[i];
        //  リクエストなしかチェック
        let days = partner.schedule_dates.length;
        if (days > 0 && partner.schedule_dates[0] != "") {
        } else {
          tmpOriginalPartners.push(partner);
        }
      }
      originalPartners = tmpOriginalPartners;

      this.isOverContinuousWork = this.isOverContinuousHoliday = this.isOverWork = false;
      this.isAllPartner = true;

    } else {

      if (!this.isAllPartner) {
        let tmpOriginalPartners: PartnerModel[] = new Array();

        for (let i = 0; i < originalPartners.length; i++) {
          let partner = originalPartners[i];
          //  リクエストがあるかチェック
          let days = partner.schedule_dates.length;
          if (days > 0 && partner.schedule_dates[0] != "") {
            tmpOriginalPartners.push(partner);
          }
        }
        originalPartners = tmpOriginalPartners;
      }

    }

    if (this.isNoConfirm) {
      //  シフト未確認のパートナーを一覧
      let tmpOriginalPartners: PartnerModel[] = new Array();

      for (let i = 0; i < originalPartners.length; i++) {
        let partner = originalPartners[i];
        //  未確認のパートナー
        if (!partner.is_confirmed) {
          tmpOriginalPartners.push(partner);
        }
      }
      originalPartners = tmpOriginalPartners;
    }



    if (this.isOverContinuousWork || this.isOverContinuousHoliday || this.isOverWork) {

      for (let i = 0; i < originalPartners.length; i++) {
        let partner = originalPartners[i];

        let addPartner: boolean = false;
        let schedule_dates = partner.schedule_dates.slice();    //  sliceをつけないと参照渡しになってしまう
        if (this.isOverWork) {
          //  20日以上のリクエストがあるかチェック
          let days = schedule_dates.length;
          if (days >= 20) {
            addPartner = true;
          }
        }

        if (!addPartner) {
          let last_shift_date = partner.last_shift_date;
          //  
          if (last_shift_date) {
            //  前月の最後にシフトに入った日付を先頭に追加
            schedule_dates.unshift(last_shift_date);
          }

        }

        if (addPartner == false && this.isOverContinuousWork) {
          //  6日以上の勤務があるかチェック
          addPartner = this.isOverContinuous(schedule_dates, 6, true);
        }

        if (addPartner == false && this.isOverContinuousHoliday) {
          //  7日以上の連続休暇があるかチェック

          let next_month_date = this.shiftService.formatNextMonthSelectedDate(1);
          let next_month_date_string = String(next_month_date.getFullYear()) + "-" + ("0" + (next_month_date.getMonth() + 1)).slice(-2) + "-01";
          schedule_dates.push(next_month_date_string);

          addPartner = this.isOverContinuous(schedule_dates, 7, false);

        }

        if (addPartner) {
          partners.push(partner);

          if (partner.partner_id == this.currentPartnerId) {
            isCurrentPartnerIdOK = true;
          }

        }



      }
      this.partners = partners;


    } else {
      this.partners = originalPartners;
      partners = this.partners;
      isCurrentPartnerIdOK = false;
    }
    //  現在選択しているパートナー
    if (isCurrentPartnerIdOK == false) {
      if (partners.length > 0) {
        //  選択していたパートナーが対象外になってしまったので、選択中のパートナーを先頭パートナーに変更
        this.currentPartnerId = partners[0].partner_id;

        this.onChange(this.currentPartnerId);
      } else {
        this.currentPartnerId = null;
      }
    }
  }

  onPartnerSchoolCountClicked(partnerId: number) {
    this.schoolShiftCounts = null;


    this.shiftService.getSchoolShiftCounts(partnerId, this.mode).subscribe(info => {
      if (info) {
        this.schoolShiftCounts = info;

        this.schoolShiftTotalCount = 0;
        let i: number;
        for (i = 0; i < this.schoolShiftCounts.length; i++) {

          this.schoolShiftTotalCount = this.schoolShiftTotalCount + this.schoolShiftCounts[i].count;
        }


      }

    });
  }

  /**
   * 連続勤務または休日チェック
   * @param schedule_dates 勤務日配列
   * @param daysThreshold 日数
   * @param on trueのときは勤務、falseのときは休日チェック
   */
  isOverContinuous(schedule_dates: string[], daysThreshold: number, on: boolean) {
    let preDate: Date;

    let m_continuousDays = 0;   //  連続勤務または休暇日数
    for (let i = 0; i < schedule_dates.length; i++) {
      let date = new Date(schedule_dates[i]);


      if (preDate) {

        let span = date.getTime() - preDate.getTime();
        let days = span / (24 * 60 * 60 * 1000);

        let continuousDays;

        if (on) {
          //  連続勤務日数をもとめる
          if (days == 1) {
            m_continuousDays++;
          } else {
            m_continuousDays = 0;
          }
          continuousDays = m_continuousDays + 1;

        } else {
          //  連続休日日数をもとめる
          continuousDays = (days - 1);
        }

        if (continuousDays >= daysThreshold) {
          return true;

        }
      }

      preDate = date;


    }

    return false;

  }
  /**
   * 講師の変更時に呼び出される
   * @param value 講師ID
   */
  onChange(value: number) {

    console.log(value);

    this.getMemo(value);

    this.onPartnerSchoolCountClicked(value);
  }


  /**
   * シフトチェック状況の取得
   * @param partnerId 講師ID
   */
  getShiftChecks(partnerId: number) {

    this.currentPartnerId = partnerId;
    this.shiftService.getShiftChecks(partnerId).subscribe(rows => {

      this.createShiftCehcks(rows);
      /*
    if (rows.length > 0) {
      //        this.shiftChecks = rows;
      this.createShiftCehcks(rows);
    } else {
      this.shiftChecks = new Array();
  
      this.requestCount = 0;
      this.approvalCount = 0;
    }
    */

    });

  }

  /**
   * シフトチェック状況の取得
   * @param partnerId 講師ID
   */
  getMemo(partnerId: number) {

    this.currentPartnerId = partnerId;
    this.shiftService.getMemo(partnerId).subscribe(rows => {


      if (rows.length > 0) {
        this.memo = rows[0];
      } else {
        this.memo = '';
      }
      this.getLpConfirmShiftInfo(partnerId);

    });

  }

  /**
   * 講師の仮シフト情報を取得
   * @param partnerId 講師ID
   */
  getLpConfirmShiftInfo(partnerId: number): void {

    //    this.tentativeShifts = null;
    this.dateToTentativeShift = null;

    this.shiftService.getLpConfirmShiftInfo(partnerId, this.mode).subscribe(info => {
      let rows: TentativeShiftModel[];
      rows = info.lpConfirmShiftInfo;
      if (rows.length > 0) {
        console.log('rows!!', rows);

        //        this.tentativeShifts = rows;

        this.dateToTentativeShift = this.utilService.objectDictionary(rows, 'date', null);

      }
      this.getShiftChecks(partnerId);

    });
  }

  /**
  * 承認状況更新
  */
  setShiftCheck(isRejected: any, shiftCheckIndex: number): void {

    let shiftCheck: ShiftCheckModel = this.shiftChecks[shiftCheckIndex];
    shiftCheck.isRejected = isRejected;

    this.shiftService.updateShiftCheck(this.currentPartnerId, shiftCheck).subscribe(rows => {

      if (rows.length > 0) {

        this.shiftChecks[shiftCheckIndex] = rows[0];

        if (isRejected == "true") {

          this.approvalCount -= 1;
        } else {

          this.approvalCount += 1;

        }

      } else {
        //  エラーになったので、チェックをもとに戻す
        shiftCheck.isRejected = isRejected == "true" ? false : true;
      }


    });

  }

  isRequested(shiftCheck: ShiftCheckModel, timeNumber: number): boolean {

    return shiftCheck.timeNumbers.indexOf(timeNumber) == -1 ? false : true;
  }

  isRejected(shiftCheck: ShiftCheckModel, timeNumber: number): boolean {

    return shiftCheck.rejectedTimeNumbers.indexOf(timeNumber) == -1 ? false : true;
  }

  /**
   * リクエストがすべて拒否の場合にtrue
   * @param shiftCheck 
   */
  isRejectedAll(shiftCheck: ShiftCheckModel): boolean {

    if (!shiftCheck.timeNumbers || shiftCheck.timeNumbers.length == 0) {
      //  リクエストがないときは拒否状態にはしない
      return false;
    }
    return this.inArray(shiftCheck.timeNumbers, shiftCheck.rejectedTimeNumbers);
  }

  /**
   * 配列の値をすべて含むかチェック
   * @param needle 検索対象の配列
   * @param search 検索もとの配列
   */
  inArray(needle: any[], search: any[]): boolean {
    for (let i = 0, n = needle.length; i < n; ++i) {
      if (search.indexOf(needle[i]) == -1) return false;
    }
    return true;
  }
  /**
   * 承認状況の変更時に呼び出される
   * @param value 
   * @param row 
   */
  onChangeShiftApprooval(value: boolean, shiftCheckIndex: number) {

    this.setShiftCheck(value, shiftCheckIndex);

  }


  createShiftCehcks(rows: ShiftCheckModel[]) {
    let requestCount = 0;
    let rejectedCount = 0;
    this.shiftChecks = new Array();
    let shiftDate = new Date(this.shiftService.selectedDate);
    shiftDate.setDate(1);
    let month = shiftDate.getMonth()
    while (1) {
      let shiftCheck = this.findShiftCheckByDate(shiftDate, rows);


      if (shiftCheck == null) {
        shiftCheck = new ShiftCheckModel();
        shiftCheck.date = shiftDate.toDateString();
      } else {
        requestCount++;
        if (this.isRejectedAll(shiftCheck)) {
          rejectedCount++;
        }

      }
      this.shiftChecks.push(shiftCheck);


      shiftDate.setDate(shiftDate.getDate() + 1); //  翌日にする
      if (month != shiftDate.getMonth()) {
        //  月が変わったので終了
        break;
      }
    }

    this.approvalCount = requestCount - rejectedCount;
    this.requestCount = requestCount;
  }

  findShiftCheckByDate(date: Date, shiftChecks: ShiftCheckModel[]) {

    if (shiftChecks) {
      let day = date.getDate();
      for (let i = 0; i < shiftChecks.length; i++) {
        let shiftCheck = shiftChecks[i];
        let shiftCheckDay = new Date(shiftCheck.date).getDate();

        if (day == shiftCheckDay) {
          console.log(date);

          return shiftCheck;
        }
        if (day < shiftCheckDay) {
          return null;
        }
      }
    }
    return null;
  }

  /**
   * 日付クリックで呼び出される
   * パートナーのシフトリクエストを切り替える
   * @param shiftCheck シフトチェックモデル
   */
  onChangeRequest(shiftCheckIndex: number) {
    let shiftCheck: ShiftCheckModel = this.shiftChecks[shiftCheckIndex];

    if (this.isRejectedAll(shiftCheck)) {
      alert('承認拒否されているシフトリクエストは変更できません。許可してから再試行してください');

    } else {
      this.shiftService.updateChankForShiftCheck(this.currentPartnerId, shiftCheck).subscribe(rows => {

        if (rows.length > 0) {

          this.shiftChecks[shiftCheckIndex] = rows[0];

        } else {
          //  エラー
        }

      });

    }

  }

  /**
   * time番号ごとの背景色を取得
   * 仮シフト登録済みのときには、スクールカラー
   * 仮シフト登録していなくて、拒否されている場合は薄い赤
   * 仮シフト登録していなくて、シフトリクエストしている場合は薄い緑
   * それ以外は白
   * @param shiftCheck 
   * @param timeNumber 
   */
  colorByTimenumber(shiftCheck: ShiftCheckModel, timeNumber: number) {

    //  仮シフト設定済みかチェック
    if (this.dateToTentativeShift && this.dateToTentativeShift[shiftCheck.date]) {
      let tentativeShift: TentativeShiftModel = this.dateToTentativeShift[shiftCheck.date][0];
      if (tentativeShift.timeNumbers.indexOf(timeNumber) == -1) {
      } else {
        return tentativeShift.schoolColor;
      }
    }

    if (this.isRejected(shiftCheck, timeNumber)) {
      return '#F2DEDE';
    } else if (this.isRequested(shiftCheck, timeNumber)) {
      return '#DFF0D8';
    }
    return '#FFFFFF';

  }

  /**
   * 仮シフト登録されている場合は、スクールが一致するか
   * @param shiftCheck 
   * @param school 
   */
  isSelectedSchool(shiftCheck: ShiftCheckModel, school: SchoolModel) {
    if (this.dateToTentativeShift && this.dateToTentativeShift[shiftCheck.date]) {
      let tentativeShift: TentativeShiftModel = this.dateToTentativeShift[shiftCheck.date][0];
      return tentativeShift.schoolId == school.school_id ? true : false;
    }
    return false;

  }

  /**
  * スクール一覧を取得
  */
  getSchools(): void {

    this.shiftService.getSchools(this.mode, null).subscribe(schools => {

      let selectSchool = new SchoolModel();
      selectSchool.school_id = 0;
      selectSchool.school_name_j = '=スクール=';
      schools.unshift(selectSchool);
      this.schools = schools;
      this.getPartners();

    });
  }

  /**
  * スクール変更時
  * 仮シフトを作成
  * 
  * @param value 
  */
  onChangeSchool(shiftCheck: ShiftCheckModel, value: number) {


    let school = this.schools[value];

    //  仮シフト設定済みかチェック
    if (this.dateToTentativeShift && this.dateToTentativeShift[shiftCheck.date]) {
      //  現在作成済み

      let tentativeShift: TentativeShiftModel = this.dateToTentativeShift[shiftCheck.date][0];

      if (value == 0) {
        //  仮シフト削除
        this.postTentativeShiftInfosForDelete(tentativeShift);

      } else {
        //  仮シフト移動
        this.postTentativeShiftInfosForMove(tentativeShift, shiftCheck, school);

      }
    } else {
      //  現在作成されていない

      if (value != 0) {

        if (this.isRejectedAll(shiftCheck)) {
          this.openModal('非承認のため仮シフトを生成できません');
          //  再表示
          this.getLpConfirmShiftInfo(this.currentPartnerId);
        } else {
          //  仮シフト作成
          this.postTentativeShiftInfosForCreate(new Date(shiftCheck.date), shiftCheck, school)
        }

      }

    }

  }

  /**
   * 仮シフト移動
   */
  postTentativeShiftInfosForMove(tentativeShift: TentativeShiftModel, shiftCheck: ShiftCheckModel, school: SchoolModel): void {

    let fromTentativeShift = new TentativeShiftModel();
    fromTentativeShift.partnerId = this.currentPartnerId;
    fromTentativeShift.schoolId = tentativeShift.schoolId;
    fromTentativeShift.boothNumber = tentativeShift.boothNumber;
    fromTentativeShift.date = tentativeShift.date;
    fromTentativeShift.timeNumbers = tentativeShift.timeNumbers;

    let toTentativeShift = new TentativeShiftModel();
    toTentativeShift.partnerId = this.currentPartnerId;
    toTentativeShift.schoolId = school.school_id;
    toTentativeShift.boothNumber = 0;   //  空いているブースに入るように
    toTentativeShift.date = tentativeShift.date;
    toTentativeShift.timeNumbers = shiftCheck.timeNumbers;


    this.shiftService.postTentativeShiftInfosForMove(fromTentativeShift, toTentativeShift, this.mode).subscribe(info => {
      if (info) {

        if (info.errorMessage) {

          //  サーバー上でエラー発生
          this.openModal(info.errorMessage);

        }
        //  再表示
        this.getLpConfirmShiftInfo(this.currentPartnerId);

      }

    });

  }
  /**
  * 仮シフト作成
  */
  postTentativeShiftInfosForCreate(date: Date, shiftCheck: ShiftCheckModel, school: SchoolModel): void {


    let tentativeShift = new TentativeShiftModel();
    tentativeShift.method = 'create';
    tentativeShift.partnerId = this.currentPartnerId;
    tentativeShift.schoolId = school.school_id;
    tentativeShift.boothNumber = 0;   //  空いているブースに入るように
    tentativeShift.timeNumbers = shiftCheck.timeNumbers;
    tentativeShift.date = date;

    this.shiftService.postTentativeShiftInfos(tentativeShift).subscribe(info => {
      if (info) {

        if (info.errorMessage) {

          //  サーバー上でエラー発生
          this.openModal(info.errorMessage);

        }

        //  再表示
        this.getLpConfirmShiftInfo(this.currentPartnerId);
      }

    });

  }

  /**
  * 仮シフト作成
  */
  postTentativeShiftInfosForDelete(tentativeShift: TentativeShiftModel): void {


    tentativeShift.method = 'delete';
    tentativeShift.partnerId = this.currentPartnerId;

    this.shiftService.postTentativeShiftInfos(tentativeShift).subscribe(info => {
      if (info) {

        if (info.errorMessage) {

          //  サーバー上でエラー発生
          this.openModal(info.errorMessage);

        }
        //  再表示
        this.getLpConfirmShiftInfo(this.currentPartnerId);
      }

    });

  }


  /**
   * モーダル表示
   * @param message メッセージ
   */
  openModal(message: string) {
    if (message) {

      console.debug(message);
      this.shiftService.errorMessage = message;

    }
  }


}
