import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserProfileService } from '../../value/index';
import { AuthService } from './auth.service';

@Injectable()

/**
 * need機能をログインなしでpartnerに見せるようにするためのクラス
 */
export class PartnerLoginCheck implements CanActivate {


    constructor(
        private auth: AuthService,
        private userProfile: UserProfileService,

    ) {
    }
    async canActivate() {
        let loggedIn = await this.userProfile.getUserProfile('loggedInAsPartner');
        console.log(loggedIn);
        // ログイン状態を確認してtokenから情報の復元が行えれば復元(主に画面リロード時に利用)
        await this.auth.loginCheckAsPartner(loggedIn);
        // 画面の遷移は行うので必ずtrueでリターン
        return await true;
    }

    public isLoggedIn() {
        return this.userProfile.getUserProfile('loggedInAsPartner');
    }


}
