import { ChankInfoModel } from 'app/models/chank-info.model';
export class ShiftCheckModel {
    date: string;                   //  対象の日付
    chankInfos: ChankInfoModel[];     //  講師がシフトリクエストしたチャンクの一覧
    isWeekday: boolean;               //  平日かどうか
    timeNumbers: number[];            //  講師がシフトリクエストしたタイム時間番号配列
    rejectedTimeNumbers: number[]; //  非承認されたタイム時間番号配列
    isRejected: boolean;    //  承認されなかったシフトリクエストの場合にtrue

}

/**
 * 非承認シフト確認機能での取得情報
 */
export class NonApprovalModel {
    date: string;                  //  対象の日付
    rejectedChankInfos: ChankInfoModel[];     //  リジェクトされたリクエストのチャンクの一覧
    notAssignedChankInfos: ChankInfoModel[];     //  アサインされていないリクエストのチャンクの一覧
    restOfAssignedChankInfos: ChankInfoModel[];     //  半日アサインされている残りのリクエストのチャンクの一覧

}
