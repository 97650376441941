import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { PdfService } from 'app/modules/util/pdf.service';
import { SchoolModel } from 'app/models/school.model';
import { NeedInfoModel, DateNeedInfosModel } from 'app/models/need-info.model';
import { ShiftConst } from '../shift.const';
import { UserProfileService } from 'app/value';
import { UtilService } from 'app/modules/util';
@Component({
  selector: 'app-need',
  templateUrl: './need.component.html',
  styleUrls: ['./need.component.scss'],
  providers: [UtilService, PdfService],
})
export class NeedComponent implements OnInit {
  public currentDay: Date;             // 選択中の日付
  public schools: SchoolModel[];       // スクール一覧
  public currentSchool: SchoolModel;
  // private needInfos: NeedInfoModel[];   //  need情報一覧
  public dateNeedInfos: DateNeedInfosModel[];//  need情報一覧

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    public shiftService: ShiftService,
    private pdfService: PdfService,
    public shiftConst: ShiftConst,
    public userProfile: UserProfileService,
    private util: UtilService,

  ) {
    this.shiftService.title = 'NEED Information';
    this.shiftService.showMenuLink = true;
  }

  ngOnInit() {
    let queryParams = this._activatedRoute.snapshot.queryParams;
    if (queryParams.year_month) {
      let date = this.util.getDateByYearmonth(queryParams.year_month);
      this.shiftService.selectedDate = date;
    }
    this.getSchools();

  }
  /**
   * スクール一覧を取得
   */
  getSchools(): void {

    this.shiftService.getSchools('partner', null).subscribe(schools => {

      if (schools.length > 0) {
        this.schools = schools;

        this.currentSchool = schools[0];
        //  need情報を取得
        this.getNeedInfo(this.currentSchool.school_id);

      }

    });
  }
  /**
   * スクール変更時
   * need情報を取得
   * 
   * @param value 
   */
  onChangeSchool(value: number) {


    this.currentSchool = this.schools[value];
    //  need情報を取得
    this.getNeedInfo(this.currentSchool.school_id);
  }


  /**
   * スクールごとのneed情報を取得
   * @param schoolId スクールID
   */
  getNeedInfo(schoolId: number): void {

    this.shiftService.getNeedInfo(schoolId).subscribe(rows => {

      this.createNeedInfos(rows);

    });
  }

  /**
   * 1月分のneed情報を生成
   * @param rows サーバーから取得したneed情報配列
   */
  createNeedInfos(rows: NeedInfoModel[]) {
    this.dateNeedInfos = new Array();
    let date = new Date(this.shiftService.selectedDate);
    date.setDate(1);
    let month = date.getMonth()
    while (1) {
      let models = this.findModelsByDate(date, rows);


      if (models == null) {
        let model = new NeedInfoModel();
        model.date = date.toDateString();
        models = new Array();
        models.push(model);
      }
      let dateNeedInfo = new DateNeedInfosModel();
      dateNeedInfo.date = date.toDateString();
      dateNeedInfo.needInfos = models;

      this.dateNeedInfos.push(dateNeedInfo);


      date.setDate(date.getDate() + 1); //  翌日にする
      if (month != date.getMonth()) {
        //  月が変わったので終了
        break;
      }
    }
  }
  // createNeedInfos(rows: NeedInfoModel[]) {
  //   this.needInfos = new Array();
  //   let date = new Date(this.shiftService.selectedDate);
  //   date.setDate(1);
  //   let month = date.getMonth()
  //   while (1) {
  //     let model = this.findModelByDate(date, rows);


  //     if (model == null) {
  //       model = new NeedInfoModel();
  //       model.date = date.toDateString();
  //     }
  //     this.needInfos.push(model);


  //     date.setDate(date.getDate() + 1); //  翌日にする
  //     if (month != date.getMonth()) {
  //       //  月が変わったので終了
  //       break;
  //     }
  //   }
  // }


  // findModelByDate(date: Date, rows: NeedInfoModel[]) {

  //   let day = date.getDate();
  //   for (let i = 0; i < rows.length; i++) {
  //     let model = rows[i];
  //     let modelDay = new Date(model.date).getDate();

  //     if (day == modelDay) {
  //       return model;
  //     }
  //     if (day < modelDay) {
  //       return null;
  //     }
  //   }
  //   return null;
  // }

  findModelsByDate(date: Date, rows: NeedInfoModel[]) {

    let models = null;
    let day = date.getDate();
    for (let i = 0; i < rows.length; i++) {
      let model = rows[i];
      let modelDay = new Date(model.date).getDate();

      if (day == modelDay) {
        if (models == null) {
          models = new Array();
        }
        models.push(model);
      }
      if (day < modelDay) {
        return models;
      }
    }
    return models;
  }



  captureScreen() {
    this.pdfService.export('NEED機能.pdf', document.getElementById('main-content'));
  }



}
