import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { DragDropService } from '../drag-drop.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ShiftClassModel } from 'app/models/shift-class.model';


import { UtilService, DataService } from '../../../modules/util';
import { HttpService } from '../../../modules/http/index';
import { AuthService } from '../../../modules/auth/index';
import { ModalService } from '../../../components/modal/index';
import { IMyDpOptions } from 'mydatepicker';

import { Subscription } from 'rxjs';

import { SchoolModel } from 'app/models/school.model';
import { ChankInfoModel } from 'app/models/chank-info.model';
import { BoothInfoModel } from 'app/models/booth-info.model';
import { TentativeShiftModel } from 'app/models/tentative-shift.model';
import { SchoolShiftCountModel } from 'app/models/school-shift-count.model';


@Component({
  selector: 'app-tentative',
  providers: [UtilService, DataService, HttpService, AuthService, ModalService],
  templateUrl: './tentative.component.html',
  styleUrls: ['./tentative.component.scss']
})
export class TentativeComponent implements OnInit {

  public shiftClassInfos: any;
  public schools: SchoolModel[];       // スクール一覧
  public closedSchools: SchoolModel[];       // 休校のスクール一覧
  public currentSchool: SchoolModel;
  public days: Date[];                 // 日付一覧
  public currentDay: Date;             // 選択中の日付
  public chankInfos: ChankInfoModel[];                   // アサイン可能なLPのチャンク情報一覧

  public boothInfos: BoothInfoModel[];    //   ブースごとのクラスの仮シフト状況とインされたLPのチャンク情報一覧

  public currentDragDropService: DragDropService;
  public assinedLpDragDropService: DragDropService;

  public isDragover: boolean[];  //  ブースごとのドラッグ状態（背景設定用）
  public isDragoverLP: boolean;  //  アサイン可能なパートナーに戻すときのドラッグ状態（背景設定用）

  public nameFilter: String;

  public isMale: boolean;
  public isFemale: boolean;
  public isA: boolean;
  public isB: boolean;


  public maleCount: number;    // アサインされている男性の人数
  public femaleCount: number;  // アサインされている女性の人数
  public rankACount: number;       // アサインされているAランクの人数
  public rankBCount: number;       // アサインされているBランクの人数

  public schoolShiftCounts: SchoolShiftCountModel[];   //  指定パートナーがアサインされているスクールごとの日数
  public selectedPartnerName: string;  //  指定パートナー
  public schoolShiftTotalCount: number;

  public mode: string;   //  'staff'のときは、コンシェルジュのシフト

  // カレンダーのフォーマット
  private myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy/mm/dd',
    disableUntil: this.getYesterday()
  };
  constructor(
    private router: Router,
    private shiftService: ShiftService,
    private activatedRoute: ActivatedRoute
  ) {
    this.shiftService.showMenuLink = true;

    this.isMale = true;
    this.isFemale = true;
    this.isA = true;
    this.isB = true;

    this.mode = activatedRoute.snapshot.queryParams['mode'];
    if (this.isStaffMode()) {
      this.shiftService.title = 'コンシェルジュ仮シフト作成';

    } else {
      this.shiftService.title = '仮シフト作成';
    }

  }

  ngOnInit() {

    this.getSchools(null);

  }

  isStaffMode(): boolean {
    return this.mode == 'staff' ? true : false;
  }
  /**
   * スクール一覧を取得
   */
  getSchools(day: Date): void {

    this.shiftService.getSchools(this.mode, day).subscribe(schools => {

      if (day) {

        //  休校ではない学校のみ取得する場合は、休校を取得
        let allSchool = this.addSchool(this.schools, this.closedSchools);
        this.closedSchools = this.dellSchool(allSchool, schools);
      }
      this.schools = schools;

      if (schools.length > 0) {

        if (day == null) {
          this.currentSchool = schools[0];
          //  日付情報を取得
          this.getSchoolDays(this.currentSchool.school_id);

        } else {
          //  日付が選択されたとき
          if (this.findSchool(this.currentSchool, this.schools) == -1) {
            //  その日はスクールがない

            this.currentSchool = schools[0];

          }
        }


      };
    });
  }

  dellSchool(allSchools: SchoolModel[], openSchools: SchoolModel[]) {
    let closedSchools = Array();
    for (let i = 0; i < allSchools.length; i++) {
      let school = allSchools[i];

      if (this.findSchool(school, openSchools) == -1) {

        //  スクールは存在していない
        closedSchools.push(school);

      }


    }
    return closedSchools;

  }

  findSchool(school: SchoolModel, schools: SchoolModel[]): number {
    let schoolId = school.school_id;
    for (let i = 0; i < schools.length; i++) {
      let school = schools[i];
      if (school.school_id == schoolId) {
        return i;
      }
    }
    return -1;
  }

  addSchool(openSchools: SchoolModel[], closeSchools: SchoolModel[]) {
    Array.prototype.push.apply(openSchools, closeSchools);
    return openSchools;

  }

  /**
   * スクールの開講日一覧を取得
   */
  getSchoolDays(schoolId: number): void {

    this.shiftService.errorMessage = "";
    this.shiftService.getSchoolDays(schoolId).subscribe(days => {

      if (days.length > 0) {
        this.days = days;

        if (days.indexOf(this.currentDay) == -1) {
          if (this.currentDay) {
            this.openModal("休校設定されているため日付を1日に変更します。");

          }
          this.currentDay = days[0];

        }
        //  仮シフト情報（アサイン可能なLP / アサイン済みLP / 仮シフト情報）を取得
        this.getTentativeShiftInfos(this.currentSchool.school_id, this.currentDay);


      } else {
        this.days = null;
        this.currentDay = null;

      }

    });
  }


  /**
   * シフト情報を取得
   * @param schoolId　スクールID
   * @param day 日付
   */
  getTentativeShiftInfos(schoolId: number, day: Date): void {

    //  コンシェルジュ用の仮シフト情報（アサイン可能なLP / アサイン済みLP / 仮シフト情報）を取得
    this.shiftService.getTentativeShiftInfos(schoolId, day, this.mode).subscribe(info => {

      if (info) {
        this.chankInfos = info.chankInfos;    //  アサイン可能なLP情報
        this.boothInfos = info.boothInfos;
        this.calcCount();
        this.isDragover = new Array();
        this.dragoverReset();
        this.dragoverLPReset();

      }

    });
  }


  /**
   * 男性人数、女性人数、Aランク人数、Bランク人数を計算
   */
  calcCount(): void {
    let maleCount = 0;
    let femailCount = 0;
    let rankACount = 0;
    let rankBCount = 0;
    if (this.boothInfos) {

      for (let i = 0; i < this.boothInfos.length; i++) {
        let boothInfo = this.boothInfos[i];
        if (boothInfo.asignedChankInfos) {

          for (let j = 0; j < boothInfo.asignedChankInfos.length; j++) {
            let chankInfo = boothInfo.asignedChankInfos[j];

            if (chankInfo.partnerSex) {
              maleCount++;

            } else {
              femailCount++;
            }

            if (chankInfo.partnerGroup == 1) {
              rankACount++;
            } else if (chankInfo.partnerGroup == 2) {
              rankBCount++;
            }
          }
        }
      }
    }

    this.maleCount = maleCount;
    this.femaleCount = femailCount;
    this.rankACount = rankACount;
    this.rankBCount = rankBCount;

  }



  // カレンダーに設定する今日の日付
  getToday() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    return { date: { year: year, month: month, day: day, hour: hour } }
  }

  // カレンダーの過去日付を無効にする用の機能昨日の日付
  getYesterday() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate() - 1;
    return { year: year, month: month, day: day }
  }


  /**
   * アサイン可能なLPのドラッグ開始
   * @param ev 
   * @param index 
   */
  dragstart(ev: any, index: number) {
    ev.dataTransfer.setData("text", ev.target.id);
    this.currentDragDropService = new DragDropService(index);
  }
  dragend(ev: any, ciIndex: number) {
    this.dragoverReset();

    if (this.currentDragDropService.isDropped()) {
      //  ドロップされた

      let index = this.currentDragDropService.getDragStartIndex();
      let droppedIndex = this.currentDragDropService.getDroppedIndex();
      if (this.chankInfos[index] && this.boothInfos[droppedIndex]) {

        let chankInfo: ChankInfoModel = this.chankInfos[index];

        let boothInfo = this.boothInfos[droppedIndex];

        //  ドロップ先のブースに追加
        //  パートナーを追加
        if (!boothInfo.asignedChankInfos) {
          boothInfo.asignedChankInfos = new Array();
        }
        boothInfo.asignedChankInfos.push(chankInfo);

        //  シフト追加
        ShiftClassModel.setFilledUpByNumbers(boothInfo.shiftClassInfos, chankInfo.timeNumbers);

        //  アサイン可能なパートナー一覧から削除
        this.chankInfos = this.chankInfos.filter(function (info, i) { return i !== index ? true : false; });

        let boothNumber = droppedIndex + 1;

        //  API呼び出してDBに追加
        this.postTentativeShiftInfos('create', chankInfo.partnerId, boothNumber, chankInfo.timeNumbers);


      }
    }
    this.currentDragDropService = null;
  }
  dragover(ev: any, boothIndex: number) {
    ev.preventDefault();
    this.dragoverSet(boothIndex);
  }
  dragleave(ev: any, boothIndex: number) {
    ev.preventDefault();
    this.dragoverReset();
  }

  drop(ev: any, boothIndex: number) {
    ev.preventDefault();

    if (this.currentDragDropService) {
      this.currentDragDropService.dropped(boothIndex);
    }

  }


  /**
  * アサイン済みのドラッグ開始
  * @param ev 
  * @param boothIndex 
  * @param chankInfoIndex 
  */
  dragstartLP(ev: any, boothIndex: number, chankInfoIndex: number) {
    console.log(ev);
    this.assinedLpDragDropService = new DragDropService(boothIndex, chankInfoIndex);
  }
  dragendLP(ev: any, boothIndex: number, chankInfoIndex: number) {

    this.dragoverLPReset();
    if (this.assinedLpDragDropService.isDropped()) {
      //  ドロップされた

      let boothIndex = this.assinedLpDragDropService.getDragStartIndex();
      let chankInfoIndex = this.assinedLpDragDropService.getDragStartSubIndex();
      if (this.boothInfos[boothIndex]) {


        let boothInfo = this.boothInfos[boothIndex];

        if (boothInfo.asignedChankInfos[chankInfoIndex]) {

          //  アサイン済みのパートナー情報を取得
          let chankInfo: ChankInfoModel = boothInfo.asignedChankInfos[chankInfoIndex];

          //  アサイン可能なLPに追加
          this.chankInfos.unshift(chankInfo);

          //  シフトから削除
          ShiftClassModel.setFilledUpByNumbers(boothInfo.shiftClassInfos, chankInfo.timeNumbers, false);

          //  アサイン済みのパートナー一覧から削除
          boothInfo.asignedChankInfos = boothInfo.asignedChankInfos.filter(function (info, i) { return i !== chankInfoIndex ? true : false; });


          let boothNumber = boothIndex + 1;
          //  API呼び出してDBから削除
          this.postTentativeShiftInfos('delete', chankInfo.partnerId, boothNumber, chankInfo.timeNumbers);

        }

      }
    }
    this.assinedLpDragDropService = null;
  }
  dragoverLP(ev: any) {
    ev.preventDefault();
    this.dragoverLPSet();
  }
  dragleaveLP(ev: any) {
    ev.preventDefault();
    this.dragoverLPReset();
  }

  dropLP(ev: any) {
    ev.preventDefault();

    if (this.assinedLpDragDropService) {
      this.assinedLpDragDropService.dropped(1);
    }

  }

  /**
   * スクール変更時
   * 日付を取得
   * 
   * @param value 
   */
  onChangeSchool(value: number) {

    console.log(value);

    this.currentSchool = this.schools[value];

    this.getSchoolDays(this.currentSchool.school_id);
  }

  /**
   * 日付変更時
   * @param value 日付
   */
  onChangeDay(value: Date) {
    this.shiftService.errorMessage = "";

    this.currentDay = value;

    this.getSchools(value);

    //  アサイン可能なLPを取得
    this.getTentativeShiftInfos(this.currentSchool.school_id, this.currentDay);
  }

  onPageUpdate() {
    //  アサイン可能なLPを取得
    this.getTentativeShiftInfos(this.currentSchool.school_id, this.currentDay);
  }

  onAssingedPartnerChankClicked(ev: any, boothIndex: number, chankInfoIndex: number) {

    if (this.boothInfos[boothIndex]) {


      let boothInfo = this.boothInfos[boothIndex];

      if (boothInfo.asignedChankInfos[chankInfoIndex]) {

        //  アサイン済みのパートナー情報を取得
        let chankInfo: ChankInfoModel = boothInfo.asignedChankInfos[chankInfoIndex];


        let boothNumber = boothIndex + 1;
        //  API呼び出して次のチャンクに変更
        this.postTentativeShiftInfos('toggle', chankInfo.partnerId, boothNumber, chankInfo.timeNumbers);


      }
    }
  }



  /**
   * 仮シフト作成 / 削除
   */
  postTentativeShiftInfos(method: string, partnerId: number, boothNumber: number, timeNumbers: number[]): void {

    this.shiftService.errorMessage = "";

    let tentativeShiftModel = new TentativeShiftModel();
    tentativeShiftModel.method = method;
    tentativeShiftModel.schoolId = this.currentSchool.school_id;
    tentativeShiftModel.date = this.currentDay;
    tentativeShiftModel.partnerId = partnerId;
    tentativeShiftModel.boothNumber = boothNumber;
    tentativeShiftModel.timeNumbers = timeNumbers;
    tentativeShiftModel.mode = this.mode;


    this.shiftService.postTentativeShiftInfos(tentativeShiftModel).subscribe(info => {
      if (info) {
        this.chankInfos = info.chankInfos;    //  アサイン可能なLP情報
        this.boothInfos = info.boothInfos;
        this.calcCount();

        this.openModal(info.errorMessage);

      }

    });
  }


  /**
   * ドラッグリセット
   * ドラッグ先の背景をもとに戻す
   */
  dragoverReset(): void {
    let count = this.boothInfos.length;
    for (let i = 0; i < count; i++) {
      this.isDragover[i] = false;
    }
  }

  /**
   * ドラッグ中の背景を設定
   * @param index ブースインデックス
   */
  dragoverSet(index: number): void {
    if (this.currentDragDropService) {
      let count = this.boothInfos.length;
      for (let i = 0; i < count; i++) {
        this.isDragover[i] = i == index ? true : false;
      }

    }
  }

  /**
   * ドラッグリセット
   * ドラッグ先の背景をもとに戻す
   */
  dragoverLPReset(): void {
    this.isDragoverLP = false;
  }

  /**
   * ドラッグ中の背景を設定
   */
  dragoverLPSet(): void {
    if (this.assinedLpDragDropService) {
      this.isDragoverLP = true;
    }
  }


  isShowByFilter(nameFilter: string, chankInfo: ChankInfoModel): boolean {
    if (!this.isA) {
      //  Aは非表示
      if (chankInfo.partnerGroup == 1) return false;
    }
    if (!this.isB) {
      //  Bは非表示
      if (chankInfo.partnerGroup == 2) return false;
    }
    if (!this.isMale) {
      //  男性は非表示
      if (chankInfo.partnerSex == true) return false;
    }
    if (!this.isFemale) {
      //  女性は非表示
      if (chankInfo.partnerSex == false) return false;
    }
    return (!nameFilter || (chankInfo.partnerName.toLowerCase().indexOf(nameFilter.toLowerCase()) != -1));
  }

  /**
   * モーダル表示
   * @param message メッセージ
   */
  openModal(message: string) {
    if (message) {

      console.debug(message);
      this.shiftService.errorMessage = message;

    }
  }

  onPartnerSchoolCountClicked(chankInfo: ChankInfoModel) {
    this.schoolShiftCounts = null;


    this.shiftService.getSchoolShiftCounts(chankInfo.partnerId, this.mode).subscribe(info => {
      if (info) {
        this.schoolShiftCounts = info;
        this.selectedPartnerName = chankInfo.partnerName;

        this.schoolShiftTotalCount = 0;
        let i: number;
        for (i = 0; i < this.schoolShiftCounts.length; i++) {

          this.schoolShiftTotalCount = this.schoolShiftTotalCount + this.schoolShiftCounts[i].count;
        }


      }

    });
  }

  /**
   * 現在のスクールが含まれているかチェック
   * @param schoolIds スクールID一覧
   */
  isInCurrentSchool(schoolIds: number[]) {
    if (schoolIds) {

      for (let i = 0; i < schoolIds.length; i++) {
        if (schoolIds[i] == this.currentSchool.school_id) {
          return true;
        }
      }

    }
    return false;

  }

  onAdminChange(checked: boolean, partnerId: number) {

    this.shiftService.updateAdminShiftDate(this.currentSchool.school_id, partnerId, this.currentDay, checked).subscribe(row => {

      if (row) {
      }


    });

  }

  /**
   * ブースのクローズ・オープン トグル
   * @param boothIndex ブースインデックス
   */
  onBoothOpenClose(boothIndex: number) {


    this.shiftService.updateBoothOpenClose(this.currentSchool.school_id, boothIndex + 1, this.currentDay).subscribe(info => {

      if (info) {
        this.boothInfos = info.boothInfos;
        this.calcCount();
        this.openModal(info.errorMessage);

      }
    });


  }

}

