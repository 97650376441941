import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangePipe } from 'app/pipes/range.pipe';
import { CommonComponentsModule } from '../../components/common-components.module';
import { MyDatePickerModule } from 'mydatepicker';
import { SimpleModalModule } from 'ngx-simple-modal';

import { PartnerComponent } from './partner.component';
import { PartnerRoutingModule } from './partner-routing.module';
import { LoginComponent } from './login/login.component';
import { LpConfirmComponent } from './lp-confirm/lp-confirm.component';

@NgModule({
  imports: [
    CommonModule,
    PartnerRoutingModule,
    CommonComponentsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    SimpleModalModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PartnerComponent,
    LoginComponent,
    LpConfirmComponent
  ]
})
export class PartnerModule { }
