import { Injectable } from '@angular/core';
import { UserProfileService, apiEndpoint } from '../../value/index';
import { DataService } from '../util/index';
import { HttpService } from '../http/index';
import { Router } from '@angular/router';
/**
 * データ処理を提供するクラス
 */
@Injectable()
export class AuthService {

  /**
   * @param HttpService
   * @param DataService
   * @constructor
   */
  constructor(
    private http: HttpService,
    private data: DataService,
    private userProfile: UserProfileService,
    private router: Router,
  ) {

  }

  async loginCheck(loggedIn: boolean): Promise<boolean> {

    console.log(loggedIn);
    let retVal: boolean;
    if (loggedIn === false || loggedIn === undefined || loggedIn === null) {

      this.router.navigate(['/login']);
      retVal = false;
    } else {
      retVal = true;
    }
    return retVal;
  }

  /**
   * ユーザーtokenが有効か問い合わせ
   * @returns Promise<boolean> トークンの有効性チェックのAPIリクエストPromise
   */
  checkToken(token: string): any {
    // const requsetUrl = apiEndpoint.checkTokenApi;
    // return this.http.promiseGet(requsetUrl, token);
  }

  // ログアウト処理
  logout() {
    // ユーザープロファイルをセット
    this.userProfile.resetUserProfile();
    // 企業情報が登録済であればindexに遷移
    this.router.navigate(['/login']);
  }

  async loginCheckAsPartner(loggedIn: boolean): Promise<boolean> {

    console.log(loggedIn);
    let retVal: boolean;
    if (loggedIn === false || loggedIn === undefined || loggedIn === null) {

      this.router.navigate(['/partner/login']);
      retVal = false;
    } else {
      retVal = true;
    }
    return retVal;
  }

  logoutAsPartner() {
    // ユーザープロファイルをセット
    this.userProfile.resetUserProfile();
    this.router.navigate(['/partner/login']);
  }

}
