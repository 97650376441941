import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SchoolInitComponent } from './school-init/school-init.component';
import { SchoolSettingComponent } from './school-setting/school-setting.component';
import { InitComponent } from './init/init.component';
import { MenuComponent } from './menu/menu.component';
import { TentativeComponent } from './tentative/tentative.component';
import { TentativeConfirmComponent } from './tentative-confirm/tentative-confirm.component';
import { LpConfirmComponent } from './lp-confirm/lp-confirm.component';
import { CheckComponent } from './check/check.component';
import { NeedComponent } from './need/need.component';
import { BlockComponent } from './block/block.component';
import { ReleaseComponent } from './release/release.component';
import { ReleasePartnerComponent } from './release-partner/release-partner.component';
import { ShiftsComponent } from './shifts.component';
import { LoginCheck } from '../../modules/auth/index';
import { LoginNoCheck } from '../../modules/auth/login_no_check';
import { NonApprovalListComponent } from './non-approval-list/non-approval-list.component';
import { PartnerSettingComponent } from './partner-setting/partner-setting.component'
import { StaffHolidayComponent } from './staff-holiday/staff-holiday.component';
import { ConciergeSettingComponent } from './concierge-setting/concierge-setting.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmTransporExpenseComponent } from './confirm-transpor-expense/confirm-transpor-expense.component';
import { MemoListComponent } from './memo-list/memo-list.component';


const routes: Routes = [
  {
    path: 'shifts',
    component: ShiftsComponent,
    children: [
      { path: 'school-init', component: SchoolInitComponent, canActivate: [LoginCheck] },
      { path: 'school-setting', component: SchoolSettingComponent, canActivate: [LoginCheck] },
      { path: 'init', component: InitComponent, canActivate: [LoginCheck] },
      { path: 'menu', component: MenuComponent, canActivate: [LoginCheck] },
      { path: 'tentative', component: TentativeComponent, canActivate: [LoginCheck] },
      { path: 'tentative-confirm', component: TentativeConfirmComponent, canActivate: [LoginCheck] },
      { path: 'lp-confirm', component: LpConfirmComponent, canActivate: [LoginCheck] },
      { path: 'check', component: CheckComponent, canActivate: [LoginCheck] },
      { path: 'need', component: NeedComponent, canActivate: [LoginNoCheck] },
      { path: 'block', component: BlockComponent, canActivate: [LoginCheck] },
      { path: 'release', component: ReleaseComponent, canActivate: [LoginCheck] },
      { path: 'non-approval-list', component: NonApprovalListComponent, canActivate: [LoginCheck] },
      { path: 'partner-setting', component: PartnerSettingComponent, canActivate: [LoginCheck] },
      { path: 'release-partner/:partnerGroup', component: ReleasePartnerComponent, canActivate: [LoginCheck] },
      { path: 'staff-holiday', component: StaffHolidayComponent, canActivate: [LoginCheck] },
      { path: 'concierge-setting', component: ConciergeSettingComponent, canActivate: [LoginCheck] },
      { path: 'confirm', component: ConfirmComponent, canActivate: [LoginCheck] },
      { path: 'confirm_transport_expense', component: ConfirmTransporExpenseComponent, canActivate: [LoginCheck] },
      { path: 'memo-list', component: MemoListComponent, canActivate: [LoginCheck] },
    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShiftsRoutingModule { }
