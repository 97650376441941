import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router } from '@angular/router';
import { SchoolSettingModel } from 'app/models/school-setting.model';

@Component({
  selector: 'app-school-setting',
  templateUrl: './school-setting.component.html',
  styleUrls: ['./school-setting.component.scss']
})
export class SchoolSettingComponent implements OnInit {
  public schoolSettings: SchoolSettingModel[];

  constructor(
    private router: Router,
    private shiftService: ShiftService,

  ) {
    this.shiftService.title = 'スクール設定';
    this.shiftService.showMenuLink = true;
  }

  ngOnInit() {
    this.getSchoolSettings();
  }

  getSchoolSettings(): void {

    this.shiftService.getSchoolSettings().subscribe(schoolSettings => {

      if (schoolSettings.length > 0) {
        this.schoolSettings = schoolSettings;

        //        let name = schoolSettings[0].school.school_name_j;
        //      console.log(name);
      }

    });
  }

  /**
   * スクール設定画面で確定ボタンクリック時
   */
  done() {
    this.setSchoolSettings();
  }

  /**
   * 値更新
   */
  setSchoolSettings(): void {


    let doneCount = 0;

    for (let i = 0; i < this.schoolSettings.length; i++) {
      let schoolSetting = this.schoolSettings[i];
      this.shiftService.updateSchoolSetting(schoolSetting).subscribe(schoolSettings => {

        if (schoolSettings.length > 0) {
          doneCount++;
          if (doneCount == this.schoolSettings.length) {
          }
        }

      });

    }

  }

  onChange(val: number, schoolIndex: number) {

    if (val != null) {

      let schoolSetting = this.schoolSettings[schoolIndex];
      this.shiftService.updateSchoolSetting(schoolSetting).subscribe(schoolSettings => {

      });

    }
  }




}
