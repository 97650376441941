export class SchoolModel {
    school_id: number;                  //  ID
    school_name_j: string;              //  学校名（日本語）
    school_name_e: string;              //  学校名（英語）
    school_color1: string;              //  スクールカラー1
    school_color2: string;              //  スクールカラー2
    school_color3: string;              //  スクールカラー3
    school_class: number;
    tag: number;
    close_date_count: number;           //  休校数
}
