import { Component, OnInit } from '@angular/core';
import { ShiftService } from '../shift.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SchoolModel } from 'app/models/school.model';
import { PartnerModel } from 'app/models/partner.model';

@Component({
  selector: 'app-partner-setting',
  templateUrl: './partner-setting.component.html',
  styleUrls: ['./partner-setting.component.scss']
})
export class PartnerSettingComponent implements OnInit {
  public schools: SchoolModel[];   //  スクール一覧
  public partners: PartnerModel[]; //  パートナー一覧
  public isEdittingEmail: boolean; //  email編集中かどうか

  public mode: string;   //  'staff'のときは、コンシェルジュのシフト

  constructor(
    private router: Router,
    public shiftService: ShiftService,
    private activatedRoute: ActivatedRoute


  ) {
    this.shiftService.showMenuLink = true;
    this.isEdittingEmail = false;

    this.mode = activatedRoute.snapshot.queryParams['mode'];
    if (this.isStaffMode()) {
      this.shiftService.title = 'コンシェルジュ設定';

    } else {
      this.shiftService.title = 'パートナー設定';
    }

  }

  ngOnInit() {
    this.getSchools();

  }
  isStaffMode(): boolean {
    return this.mode == 'staff' ? true : false;
  }

  /**
   * スクール一覧を取得
   */
  getSchools(): void {

    this.shiftService.getSchools(this.mode, null).subscribe(schools => {

      if (schools.length > 0) {
        this.schools = schools;

        //  パートナー一覧を取得
        this.getAllPartnerSchool();

      }

    });
  }


  /**
   * パートナー一覧を取得
   */
  getAllPartnerSchool(): void {

    this.shiftService.getAllPartnerSchool(this.mode).subscribe(partners => {

      if (partners.length > 0) {
        this.partners = partners;

      }

    });
  }

  inSchoolArray(schoolId: number, schools: SchoolModel[]): boolean {

    for (let i = 0; i < schools.length; i++) {
      if (schools[i].school_id == schoolId) {
        return true;
      }
    }
    return false;
  }

  onUpdateSchool(value: any, schoolId: number, partnerId: number): void {
    console.log('onUpdateSchool:' + value);

    this.shiftService.updatePartnerSchool(schoolId, partnerId, value ? 'insert' : 'delete').subscribe(partners => {


      if (partners.length > 0) {
        let partner = partners[0];
        for (let i = 0; i < this.partners.length; i++) {
          if (this.partners[i].partner_id == partnerId) {
            this.partners[i] = partner;
          }
        }

      }

    });


  }

  onEditEmail(): void {
    this.isEdittingEmail = this.isEdittingEmail ? false : true;

    if (this.isEdittingEmail === false) {
      //  email更新

      this.shiftService.updatePartnersEmail(this.partners).subscribe(partners => {

      });
    }

  }

  /**
   * パートナー名を取得
   * @param model パートナー
   */
  partnerName(model: any) {
    return PartnerModel.partnerName(model);
  }
}