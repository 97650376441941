// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

//  ng buildで読み込まれるのはこのファイル！！

export const environment = {
  production: true,
  //apiBase: 'https://dev-b-system-api.b-myself.com/api'
  // apiBase: 'https://api-bsystem.b-myself.com/api'
  //apiBase: 'http://byzoo.system.api/api'

  //  xserverテスト用
  apiBase: 'https://api-dev-admin-b-myself.fitmo.jp/api'
  //  apiBase: 'http://byzoo.system.api/api',
  //  host: 'localhost:4400',

};
