import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()

/**
 * need機能をログインなしでpartnerに見せるようにするためのクラス
 */
export class LoginNoCheck implements CanActivate {


    constructor(
    ) {
    }

    async canActivate() {
        return await true;
    }

}
